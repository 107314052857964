<template>
  <div id="bsModalAddKYCFiles" aria-labelledby="bsModalLabel" class="modal fade"
       data-backdrop="static" data-keyboard="false"
       data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Passport/Photo ID</label>
            <input id="passport" ref="passport"
                   type="file" @change="uploadFile('passport')"/>
            <div v-if="files.passport" style="margin-top: 8px">
              <silent-box :image="{
                src: files.passport,
                thumbnailHeight: '60px'
              }">
              </silent-box>
            </div>
          </div>

          <div class="form-group">
              <label>Profile image</label>
              <input id="avatar" ref="avatar" type="file"
                     @change="uploadFile('avatar')"/>
              <div v-if="files.avatar" style="margin-top: 8px">
                <silent-box :image="{
                  src: files.avatar,
                  thumbnailHeight: '60px'
                }">
                </silent-box>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { saveNotification } from "../../../lib/utils";

export default {
  name: "AddKYCFilesModal",
  data() {
    return {
      title: "",
      user: null,
      confirmHook: () => {},
      files: {
        passport: "",
        avatar: "",
      },
      passport: "",
      avatar: "",
      call: "INSERT",
    };
  },
  methods: {
    getFiles() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        POSDATA: "",
        Call: "SHOW",
        KeyData: "FILES_" + this.user.ID,
        MerchantID: this.user.ID,
        APIKey: this.user.APIKey,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/MarketAPI`, {
        params: payload,
      }).then(response => {
        if (response.data !== "nothing found") {
          if (typeof response.data.avatar === "undefined") {
            this.files = {
              passport: "",
              avatar: "",
            };
          } else {
            this.files = response.data;
            this.call = "UPDATE";
            this.confirmHook(this.files);
          }
        }
        this.isLoading = false;
      }).catch(error => {
        console.log(error.response);
        this.isLoading = false;
      });
    },
    isValidImageFile(file) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.bmp|\.tiff|\.webp)$/i;
      return allowedExtensions.exec(file.name) && file.type.startsWith("image/");
    },
    uploadFile(key) {
      const files = (key === "passport") ? this.$refs.passport.files : this.$refs.avatar.files;
      if (files.length > 0) {
        const file = files[0];
        // Max file size 15 MB
        if (file.size > 15360000) {
          // Clear file
          this.$refs.passport.value = "";
          this.$refs.avatar.value = "";
          this.$toast.fire("", "File size should be less than 15 MB", "error");
          saveNotification("File size should be less than 15 MB");
        } else if (!this.isValidImageFile(file)) {
          this.$refs.passport.value = "";
          this.$refs.avatar.value = "";
          this.$toast.fire("", "Please provide a valid image file i.e. jpg, jpeg, png, bmp, gif, tiff, webp", "error");
          saveNotification("Please provide a valid image file i.e. jpg, jpeg, png, bmp, gif, tiff, webp");
        } else {
          const fd = new FormData();
          fd.append("files123", file);
          fd.append("MerchantID", this.user.ID);
          fd.append("secure", true);
          fd.append("insert", true);
          // Upload it to amazon S3
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.post(`${apiBaseUrl}/MarketAPI?POSBLOB`, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            if (response.status === 200) {
              const action = this.call;
              if (key === "passport") {
                this.passport = response.data;
                this.files.passport = this.passport;
              } else {
                this.avatar = response.data;
                this.files.avatar = this.avatar;
              }
              this.$refs.passport.value = "";
              this.$refs.avatar.value = "";
              const payload = {
                POSDATA: "",
                Call: action,
                KeyData: "FILES_" + this.user.ID,
                ValueData: JSON.stringify(this.files),
                MerchantID: this.user.ID,
                APIKey: this.user.APIKey,
                edit: true,
                output: "json",
              };
              this.$http.get(`${apiBaseUrl}/MarketAPI`, {
                params: payload,
              }).then(response => {
                this.isLoading = false;
                this.call = "UPDATE";
                this.getFiles();
              }).catch(error => {
                console.log(error.response);
                this.isLoading = false;
              });
            } else {
              this.isLoading = false;
            }
          }).catch(error => {
            console.log(error);
          });
        }
      }
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalAddKYCFiles").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("add-kyc-files-modal", (args) => {
      this.title = args.title;
      this.user = args.data;
      if (this.user) {
        this.files = this.user.files ? this.user.files : {
          passport: "",
          avatar: "",
        };
      }
      if (typeof this.files.avatar !== "undefined" && this.files.avatar !== "") {
        this.call = "UPDATE";
      } else {
        this.call = "INSERT";
      }
      this.confirmHook = args.confirm;
      $("#bsModalAddKYCFiles").modal("show");
    });
  },
};
</script>

<style scoped></style>
