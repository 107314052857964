var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"bsModalVTokenCheque","aria-labelledby":"bsModalLabel","data-backdrop":"static","data-keyboard":"false","data-toggle":"modal","role":"dialog"},on:{"click":function($event){return _vm.clickOverlay()}}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"aria-label":"Close","type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('h4',{staticClass:"modal-title",attrs:{"id":"bsModalLabel"}},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"modal-body"},[_c('va-tabs',{attrs:{"padding":'padding: 0'},on:{"selectedTab":_vm.selectedTab}},[_c('div',{staticClass:"active",attrs:{"slot":"content","id":"create-vToken","data-title":'Create VToken'},slot:"content"},[(_vm.successMessageCreate)?_c('div',{staticClass:"form-group text-center"},[_c('img',{attrs:{"src":'https://chart.googleapis.com/chart?chs=300&cht=qr&chl=' + _vm.successMessageCreate,"alt":""}}),_c('br'),_c('input',{ref:"copy",attrs:{"type":"hidden"},domProps:{"value":_vm.successMessageCreate}}),_c('span',{staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(_vm.successMessageCreate))]),_vm._v(" "),_c('button',{staticClass:"btn btn-outline-success btn-sm d-inline-block",attrs:{"type":"button"},on:{"click":function($event){return _vm.copyToClipboard(_vm.successMessageCreate)}}},[_c('img',{staticClass:"pointer w-20",attrs:{"alt":"","src":require("@/assets/images/icon_clipboard.svg")}})])]):_vm._e(),_c('ValidationObserver',{ref:"observerCreate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createToken)}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"amount","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex align-items-center justify-between mt-8",attrs:{"for":"vtoken-amount"}},[_c('span',[_vm._v("Amount")]),(_vm.record)?_c('a',{staticClass:"pointer",on:{"click":function($event){_vm.form.amount = _vm.record.TotalBalance}}},[_vm._v(" "+_vm._s(_vm.record.TotalBalance + ' ' + _vm.record.LongName))]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.amount),expression:"form.amount"}],staticClass:"form-control",attrs:{"id":"vtoken-amount","type":"text"},domProps:{"value":(_vm.form.amount)},on:{"keyup":function($event){return _vm.trimValue(_vm.form, 'amount')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "amount", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-flat mb-8",attrs:{"disabled":_vm.loader !== '' || _vm.balance === 0,"type":"submit"}},[_vm._v(" Create "),(_vm.loader === 'create')?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin ml-8"})]):_vm._e()])])],1)]}}])})],1),_c('div',{attrs:{"slot":"content","id":"claim-vToken","data-title":'Claim VToken'},slot:"content"},[_c('ValidationObserver',{ref:"observerClaim",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.claimToken)}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"amount","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex align-items-center justify-between mt-8 mb-8"},[_c('label',{attrs:{"for":"token"}},[_vm._v("VToken")]),_c('img',{staticClass:"w-24 pointer",attrs:{"alt":"","src":require("@/assets/images/qr_image.png")},on:{"click":function($event){_vm.isScanner = !_vm.isScanner}}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formClaim.token),expression:"formClaim.token"}],staticClass:"form-control",attrs:{"id":"token","type":"text"},domProps:{"value":(_vm.formClaim.token)},on:{"keyup":function($event){return _vm.trimValue(_vm.formClaim, 'token')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formClaim, "token", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.isScanner)?_c('div',{staticClass:"row mb-8"},[_c('div',{staticClass:"col-md-offset-3 col-md-6"},[_c('qrcode-stream',{on:{"decode":_vm.onDecode,"init":_vm.onInit}})],1)]):_vm._e(),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-flat mb-8",attrs:{"disabled":_vm.loader !== '',"type":"submit"}},[_vm._v(" Claim "),(_vm.loader === 'claim')?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin ml-8"})]):_vm._e()])])],1)]}}])})],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }