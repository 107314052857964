<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider v-slot="{ errors }" class="form-group" name="transaction confirm url"
                                rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="confirm">Payment Confirmation URL</label>
                <div class="col-md-8">
                  <input id="confirm" v-model="form.TransactionConfirmURL" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="transaction fail url"
                                rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="fail">Payment Fail URL</label>
                <div class="col-md-8">
                  <input id="fail" v-model="form.TransactionFailURL" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="email notifications"
                                rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="notifications">Email Notifications</label>
                <div class="col-md-8">
                  <select id="notifications" v-model="form.EmailNotifications" class="form-control">
                    <option value="0">Off</option>
                    <option value="1">On</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <div class="text-right">
              <button type="submit" class="btn btn-primary btn-flat"
                      :disabled="isLoading">
                Update
                <span v-if="isLoading">
                  <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                </span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { generatePayload, logout, saveNotification } from "@/lib/utils";
import { SAVE_ACCOUNT } from "@/store/keys";

export default {
  name: "PaymentUrls",
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        TransactionConfirmURL: "",
        TransactionFailURL: "",
        EmailNotifications: 0,
      },
      isLoading: false,
    };
  },
  watch: {
    user(val) {
      if (val) {
        this.updateValues();
      }
    },
  },
  methods: {
    updateValues() {
      if (this.user) {
        this.form = {
          TransactionConfirmURL: this.user.TransactionConfirmURL,
          TransactionFailURL: this.user.TransactionFailURL,
          EmailNotifications: this.user.EmailNotifications,
        };
      }
    },
    onSubmit: function () {
      const form = JSON.parse(JSON.stringify(this.form));
      let payload = { ...this.user };
      payload.EmailNotifications = form.EmailNotifications;
      payload.TransactionConfirmURL = form.TransactionConfirmURL;
      payload.TransactionFailURL = form.TransactionFailURL;
      payload = generatePayload(payload);
      this.isLoading = true;
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$emit("refresh", this.user.ID);
          this.$toast.fire("", "Payment urls updated successfully", "success");
          saveNotification("Payment urls updated successfully");
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
  },
  mounted() {
    this.updateValues();
  },
};
</script>

<style scoped>

</style>
