<template>
  <div id="bsModalLogs" aria-labelledby="bsModalLabel" class="modal fade"
       data-backdrop="static" data-keyboard="false"
       data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="logs" rules="required" tag="div">
                <label for="logs">Logs</label>
                <textarea id="logs" v-model="form.logs" class="form-control" rows="4"></textarea>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  Update
                  <span v-if="isLoading">
                    <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                  </span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "LogsModal",
  data() {
    return {
      isLoading: false,
      title: "",
      user: null,
      form: {
        logs: "",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    confirm: function (logs) {
      this.confirmHook(logs);
      this.close();
    },
    onSubmit: function () {
      this.isLoading = true;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const encodeForm = data => {
        return Object.keys(data).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&");
      };
      const payload = {
        Call: "KYCLOG",
        MerchantID: this.user.ID,
        APIKey: this.user.APIKey,
        kyclog: this.tz().format("DD/MM/YYYY HH:mm:ss") + " " + this.currentUser.Username + ": " + this.form.logs + "\n" + this.user.Logs,
        output: "json",
      };
      this.$http.post(`${apiBaseUrl}/v2REAPI`, encodeForm(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        this.isLoading = false;
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            this.$toast.fire("", "Logs updated successfully", "success");
            saveNotification("Logs updated successfully");
            this.confirm(payload.kyclog);
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            this.close();
            logout();
          }
        }
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      this.form.logs = "";
      this.$nextTick(() => this.$refs.observer.reset());
      $("#bsModalLogs").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("logs-modal", (args) => {
      this.title = args.title;
      this.user = args.data || null;
      this.confirmHook = args.confirm;
      $("#bsModalLogs").modal("show");
    });
  },
};
</script>

<style scoped></style>
