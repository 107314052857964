<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <!-- Input Currency -->
        <div class="form-group">
          <label class="title">Default Input Currency</label>
          <div class="flex align-items-center justify-between">
            <span v-if="selectedInputCurrency">
              {{ selectedInputCurrency.Name + ' ' + selectedInputCurrency.LongName }}
            </span>
            <button class="btn btn-primary btn-flat" :disabled="loader !== ''"
              @click="launchInputCurrencyModal()">Change</button>
          </div>
        </div>
        <!-- Active Currencies -->
        <div class="form-group">
          <label class="title">Active Currencies</label>
          <div class="mb-8">
            <span v-for="(activeCurrency, index) in activeCurrencies" :key="index" class="mr-8">
              <img :src="generateIconUrl(activeCurrency.Name)" alt="" class="w-32 mr-8"/>
              {{ activeCurrency.Name }}
            </span>
          </div>
          <div class="text-right">
            <button :disabled="loader !== ''" class="btn btn-primary btn-flat"
              @click="launchActiveCurrenciesModal()">Change</button>
          </div>
        </div>
        <!-- Preferred Currency -->
        <div class="form-group">
          <label class="title">Preferred Currency</label>
          <div class="flex align-items-center justify-between">
            <p v-if="preferredAltCoin">{{ preferredAltCoin.Name + ' ' + preferredAltCoin.LongName }}</p>
            <button :disabled="loader !== ''" class="btn btn-primary btn-flat"
                    @click="launchPreferredCurrencyModal()">Change
            </button>
          </div>
          <div>
            <span class="mr-8">Auto SWAP to Preferred Currency?</span>
            <toggle-button v-model="autoSwapPreferredAltCoin"
                           :color="{checked: '#89B92E', unchecked: '#FF0000', disabled: '#CCCCCC'}"
                           :labels="{checked: 'Yes', unchecked: 'No'}"
                           :sync="true" :value="autoSwapPreferredAltCoin"
                           @change="updateAutoSwap()"/>
          </div>
        </div>
        <!-- Currency excluded from automated daily payouts -->
        <div class="form-group">
          <label class="title" for="excludedCoin">Currency Excluded From Automated Daily Payouts</label>
          <div class="flex align-items-center justify-between">
            <select id="excludedCoin" v-model="excludedCoin" class="form-control" style="width: 250px">
              <option value="0">None</option>
              <option v-for="(currency, index) in selectedAltCoins" :key="index" :value="currency.ID">
                {{ currency.Name + ' ' + currency.LongName }}
              </option>
            </select>
            <button :disabled="loader !== ''" class="btn btn-primary btn-flat" @click="updateExcludedCurrency()">
              Update
              <span v-if="loader === 'excluded'">
                <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
              </span>
            </button>
          </div>
        </div>
        <!-- Flexibility Percentage -->
        <div class="form-group mb-0">
          <label class="title">Flexibility Percentage</label>
          <div class="flex align-items-center justify-between">
            <div class="flex align-items-center" style="flex-grow: 1">
              <vue-slider v-model="flexibilityPercentage" v-bind="sliderOptions"/>
              <div class="ml-8 text-bold" style="font-size: 16px;">{{ flexibilityPercentage }}</div>
            </div>
            <button :disabled="loader !== ''" class="btn btn-primary btn-flat"
                    @click="updateFlexibilityPercentage()">
              Update
              <span v-if="loader === 'flexibility'">
                <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Input Currency Modal -->
    <input-currency-modal></input-currency-modal>
    <!-- Active Currencies Modal -->
    <active-currencies-modal></active-currencies-modal>
    <!-- Preferred Currency Modal -->
    <preferred-currency-modal></preferred-currency-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSlider from "vue-slider-component";
import { generateIconUrl, generatePayload, logout, saveNotification } from "@/lib/utils";
import "vue-slider-component/theme/antd.css";
import { SAVE_ACCOUNT } from "@/store/keys";
import PreferredCurrencyModal from "@/components/merchant-detail/modals/PreferredCurrencyModal";
import InputCurrencyModal from "@/components/merchant-detail/modals/InputCurrencyModal";
import ActiveCurrenciesModal from "@/components/merchant-detail/modals/ActiveCurrenciesModal";

export default {
  name: "WalletCurrencies",
  components: {
    ActiveCurrenciesModal,
    InputCurrencyModal,
    PreferredCurrencyModal,
    VueSlider,
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectedAltCoins: [],
      preferredAltCoinID: null,
      autoSwapPreferredAltCoin: false,
      excludedCoin: 0,
      flexibilityPercentage: 60,
      loader: "",
      sliderOptions: {
        dotSize: 14,
        width: "60%",
        height: 4,
        min: 60,
        max: 100,
        interval: 1,
      },
    };
  },
  computed: {
    ...mapGetters([
      "onlineAltCoins",
    ]),
    selectedInputCurrency() {
      if (this.onlineAltCoins.length > 0 && Object.keys(this.user).length > 0) {
        return this._.find(this.onlineAltCoins, {
          ShortName: this.user.PayoutCurrencyShortName,
        });
      }
      return null;
    },
    activeCurrencies() {
      if (this.selectedAltCoins.length > 0) {
        return this._.orderBy(this.selectedAltCoins, [coin => coin.Name.toLowerCase()], "asc");
      }
      return this.getSelectedAltCoins();
    },
    preferredAltCoin() {
      if (this.onlineAltCoins.length > 0 && Object.keys(this.user).length > 0 && this.preferredAltCoinID) {
        return this._.find(this.onlineAltCoins, {
          ID: Number.parseInt(this.preferredAltCoinID, 10),
        });
      }
      return {};
    },
  },
  watch: {
    user(val) {
      if (val) {
        this.updateValues();
      }
    },
  },
  methods: {
    updateValues() {
      this.flexibilityPercentage = this.user.FlexibilityPercentage;
      this.excludedCoin = this.user.AltCoinFiatPayOutExcludes;
      this.preferredAltCoinID = this.user.PreferredCoinID;
      this.autoSwapPreferredAltCoin = this.user.SWAPPreferredCoinID;
      this.getSelectedAltCoins();
    },
    getSelectedAltCoins() {
      if (Object.keys(this.user).length > 0) {
        this.selectedAltCoins = [];
        const selectedAltCoins = this.user.SelectedAltCoins.split(",");
        this.onlineAltCoins.forEach(coin => {
          if (selectedAltCoins.indexOf(coin.ID.toString()) !== -1) {
            this.selectedAltCoins.push(coin);
          }
        });
      }
      return this._.orderBy(this.selectedAltCoins, [coin => coin.Name.toLowerCase()], "asc");
    },
    generateIconUrl(name) {
      return generateIconUrl(name);
    },
    launchInputCurrencyModal() {
      this.$bus.$emit("input-currency-modal", {
        title: "Choose Input Currency",
        data: this.user,
        confirm: () => {
          this.$emit("refresh", this.user.ID);
        },
      });
    },
    launchActiveCurrenciesModal() {
      this.$bus.$emit("active-currencies-modal", {
        title: "Choose Currencies",
        data: this.user,
        confirm: () => {
          this.$emit("refresh", this.user.ID);
        },
      });
    },
    launchPreferredCurrencyModal() {
      this.$bus.$emit("preferred-currency-modal", {
        title: "Choose Preferred Currency",
        data: this.user,
        confirm: () => {
          this.$emit("refresh", this.user.ID);
        },
      });
    },
    updateAutoSwap() {
      if (Object.keys(this.user).length > 0) {
        let payload = { ...this.user };
        payload.SWAPPreferredCoinID = this.autoSwapPreferredAltCoin;
        payload = generatePayload(payload);
        this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
          if (response.status === 200) {
            this.$emit("refresh", this.user.ID);
            // Show success toast
            this.$toast.fire("", "Auto swap settings updated successfully", "success");
          }
        }).catch(error => {
          this.$toast.fire("", error.data.message, "error");
          if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
            logout();
          }
        });
      }
    },
    updateExcludedCurrency() {
      if (Object.keys(this.user).length > 0) {
        this.loader = "excluded";
        let payload = { ...this.user };
        payload.AltCoinFiatPayOutExcludes = this.excludedCoin;
        payload = generatePayload(payload);
        this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
          this.loader = "";
          if (response.status === 200) {
            this.$emit("refresh", this.user.ID);
            // Show success toast
            this.$toast.fire("", "Excluded coin updated successfully", "success");
            saveNotification("Excluded coin updated successfully");
          }
        }).catch(error => {
          this.loader = "";
          this.$toast.fire("", error.data.message, "error");
          if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
            logout();
          }
        });
      }
    },
    updateFlexibilityPercentage() {
      if (Object.keys(this.user).length > 0) {
        this.loader = "flexibility";
        let payload = { ...this.user };
        payload.FlexibilityPercentage = this.flexibilityPercentage;
        payload = generatePayload(payload);
        this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
          this.loader = "";
          if (response.status === 200) {
            this.$emit("refresh", this.user.ID);
            // Show success toast
            this.$toast.fire("", "Flexibility percentage updated successfully", "success");
            saveNotification("Flexibility percentage updated successfully");
          }
        }).catch(error => {
          this.loader = "";
          this.$toast.fire("", error.data.message, "error");
          if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
            logout();
          }
        });
      }
    },
  },
  mounted() {
    if (this.user) {
      this.updateValues();
    }
  },
};
</script>

<style lang="scss" scoped></style>
