<template>
  <div id="bsModalCryptoAddress" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <button :disabled="loader !== ''"
                      class="btn btn-primary btn-flat btn-block" @click="create()">
                Create
                <span v-if="loader === 'create'">
                  <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                </span>
              </button>
            </div>
            <div class="col-md-6">
              <button :disabled="loader !== ''"
                      class="btn btn-primary btn-flat btn-block" @click="consolidate()">
                Consolidate
                <span v-if="loader === 'consolidate'">
                  <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                </span>
              </button>
            </div>
          </div>
          <div v-if="ltaData.length && !consolidateData" class="row mt-2">
            <div class="col-md-12">
              <h4>LTR Addresses created</h4>
              <div v-for="(record, index) in ltaData" :key="index">
                <div class="text-center">
                  <img
                      :src="'https://chart.googleapis.com/chart?chs=300&cht=qr&chl=' + record.CoinAddressUsed"
                      alt/>
                </div>
                <p class="font-medium text-center">
                  {{ record.CoinAddressUsed }}
                  <br/>
                  will expire on {{ record.ExpiryDate }}
                  <br/>
                  has balance {{ record.AddressBalance }}
                </p>
              </div>
            </div>
          </div>

          <p v-if="consolidateData && ltaData.length === 0"
             class="font-medium sp16 mt-2">{{ consolidateData }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveNotification } from "@/lib/utils";

export default {
  name: "CryptoAddressModal",
  data() {
    return {
      loader: "",
      title: "",
      user: null,
      record: null,
      isCreate: false,
      ltaData: [],
      consolidateData: "",
      apiBaseUrl: process.env.VUE_APP_API_URL,
      confirmHook: () => {
      },
    };
  },
  methods: {
    loadLongTermAddress() {
      this.consolidateData = "";
      this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "LTRAddressOverview",
          MerchantID: this.user.ID,
          APIKey: this.user.APIKey,
          AltCoinID: this.record.AltCoinID,
          output: "json",
        },
      }).then(response => {
        this.ltaData = response.data;
        if (this.ltaData.length > 0 && this.isCreate) {
          this.isCreate = false;
          this.confirmHook();
        }
      }).catch(error => {
        console.log(error);
      });
    },
    create() {
      this.loader = "create";
      this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "LTRNewAddress",
          MerchantID: this.user.ID,
          APIKey: this.user.APIKey,
          AltCoinID: this.record.AltCoinID,
          output: "json",
        },
      }).then(response => {
        this.loader = "";
        if (response.data !== "Merchant custom Address Limit of 5 reached") {
          this.isCreate = true;
          this.loadLongTermAddress();
          this.$toast.fire("", "LTR address created successfully", "success");
          saveNotification("LTR address created successfully");
        } else {
          this.$toast.fire("", response.data, "error");
          saveNotification(response.data);
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    consolidate() {
      this.loader = "consolidate";
      this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "LTRConsolidate",
          MerchantID: this.user.ID,
          APIKey: this.user.APIKey,
          AltCoinID: this.record.AltCoinID,
          output: "json",
        },
      }).then(response => {
        this.loader = "";
        if (response.data.toLowerCase() === "completed") {
          this.ltaData = [];
          this.consolidateData = "Consolidate to main balance, please wait...";
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      this.consolidateData = "";
      this.ltaData = [];
      $("#bsModalCryptoAddress").modal("hide");
      // this.$bus.$off('crypto-address-modal', this.listener)
    },
    listener(args) {
      this.title = args.title;
      this.user = args.user;
      this.record = args.record;
      this.confirmHook = args.confirm;
      this.isCreate = false;
      const modalElm = $("#bsModalCryptoAddress");
      modalElm.modal("show");
      const self = this;
      modalElm.on("shown.bs.modal", function () {
        self.loadLongTermAddress();
      });
    },
  },
  mounted() {
    this.$bus.$on("crypto-address-modal", this.listener);
  },
};
</script>

<style scoped></style>
