<template>
  <div id="bsModalActiveCurrencies" aria-labelledby="bsModalLabel" class="modal fade"
       data-backdrop="static" data-keyboard="false"
       data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="confirm()">
            <span
              aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div class="flex justify-between" style="margin: 0 15px 16px;">
            <button class="btn btn-primary btn-flat" type="button" @click="selectAll()">Select All</button>
            <button class="btn btn-primary btn-flat" type="button" @click="selectPopularOnly()">Select Popular Only
            </button>
            <button class="btn btn-primary btn-flat" type="button" @click="unselectAll()">Unselect All</button>
          </div>
          <div class="search-container" style="margin: 0 15px 16px;">
            <input v-model="keyword" aria-label="Search" class="form-control" type="text"/>
          </div>
          <div class="text-center">
            <div v-for="(coin, index) in coinsList" :key="index"
                 :class="{'is-selected': isSelected(coin) }" class="box box-coin" @click="toggleCoinSelection(coin)">
              <img :src="generateIconUrl(coin.Name)" alt/>
              <p>{{ coin.Name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { generateIconUrl, generatePayload, logout, saveNotification } from "@/lib/utils";
import { SAVE_ACCOUNT } from "@/store/keys";

export default {
  name: "ActiveCurrenciesModal",
  data() {
    return {
      isLoading: false,
      keyword: "",
      title: "",
      user: null,
      selectedAltCoins: "",
      selectedAltCoinsList: [],
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "onlineAltCoins",
    ]),
    coinsList() {
      const coins = this._.orderBy(this.onlineAltCoins, [coin => coin.Name.toLowerCase()], "asc");
      return coins.filter(coin => {
        if (this.keyword && this.keyword !== "") {
          return (
            coin.Name.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1 ||
            coin.ShortName.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1 ||
            coin.LongName.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1
          );
        }
        return true;
      });
    },
  },
  methods: {
    confirm: function () {
      let payload = { ...this.user };
      payload.SelectedAltCoins = this.selectedAltCoins;
      payload = generatePayload(payload);
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        if (response.status === 200) {
          this.$toast.fire("", "Active currencies updated successfully", "success");
          saveNotification("Active currencies updated successfully");
          this.confirmHook();
          this.close();
        }
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
    selectAll() {
      this.selectedAltCoinsList = this.onlineAltCoins;
      this.selectedAltCoins = this.selectedAltCoinsList.map(x => x.ID).join(",");
    },
    selectPopularOnly() {
      this.selectedAltCoinsList = this.onlineAltCoins.filter(coin => {
        return coin.Buy === true && coin.isFiat === false;
      });
      this.selectedAltCoins = this.selectedAltCoinsList.map(x => x.ID).join(",");
    },
    unselectAll() {
      this.selectedAltCoinsList = [];
      this.selectedAltCoins = "1,8,9,2";
    },
    isSelected(coin) {
      return this._.findIndex(this.selectedAltCoinsList, { ID: coin.ID }) !== -1;
    },
    toggleCoinSelection(selectedCoin) {
      const index = this._.findIndex(this.selectedAltCoinsList, {
        ID: selectedCoin.ID,
      });
      if (index !== -1) {
        this.selectedAltCoinsList.splice(index, 1);
      } else {
        this.selectedAltCoinsList.push(selectedCoin);
      }
      this.selectedAltCoins = this.selectedAltCoinsList.map(x => x.ID).join(",");
    },
    generateIconUrl(name) {
      return generateIconUrl(name);
    },
    onSubmit: function () {
      this.isLoading = true;
      let payload = { ...this.user };
      payload.SelectedAltCoins = this.selectedAltCoins;
      payload = generatePayload(payload);
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$toast.fire("", "Active coins updated successfully", "success");
          saveNotification("Active coins updated successfully");
          // Close modal
          this.confirm();
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.confirm();
    },
    close() {
      $("#bsModalActiveCurrencies").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("active-currencies-modal", (args) => {
      this.title = args.title;
      this.user = args.data || null;
      this.confirmHook = args.confirm;
      $("#bsModalActiveCurrencies").modal("show");
      if (this.user) {
        this.selectedAltCoins = this.user.SelectedAltCoins;
        const selectedCoinsList = this.selectedAltCoins.split(",");
        this.selectedAltCoinsList = this.onlineAltCoins.filter(coin => {
          return selectedCoinsList.indexOf(coin.ID.toString()) !== -1;
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.box-coin {
  height: 84px;
  width: 84px;
  padding: 7px;
  line-height: 1;
  margin: 5px;
  text-align: center;
  border: 2px solid #b0b0b0;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;

  &.is-selected {
    border-color: #89B92E;
  }

  img {
    width: 40px;
    height: 40px;
    margin: 4px;
  }

  p {
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    word-break: break-all;
    color: #202020;
    margin-bottom: 0;
  }
}

.icon-box {
  display: flex;
  align-items: center;
  width: 180px;
  float: left;

  .icon16 {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
</style>
