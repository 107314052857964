<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider v-slot="{ errors }" class="form-group" name="account id" rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="accountID">Merchant/Account ID</label>
                <div class="col-md-8">
                  <input id="accountID" v-model="form.ID" class="form-control" readonly type="text" />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="account api key" rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="accountApiKey">Account API Key</label>
                <div class="col-md-8">
                  <input id="accountApiKey" v-model="user.APIKey" class="form-control" readonly type="text" />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="security code" rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="securityCode">Security Code</label>
                <div class="col-md-8">
                  <input id="securityCode" v-model="user.SecurityCode" class="form-control" readonly type="text" />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="organization name" rules="required"
              tag="div">
              <div class="row">
                <label class="col-md-4" for="shopTitle">Organization</label>
                <div class="col-md-8">
                  <input id="shopTitle" v-model="form.ShopTitle" class="form-control" type="text" />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="nick name" rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="ownerNickName">Nick Name</label>
                <div class="col-md-8">
                  <input id="ownerNickName" v-model="user.OwnerNickName" class="form-control" readonly type="text" />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="password" tag="div">
              <div class="row">
                <label class="col-md-4" for="password">Password</label>
                <div class="col-md-8">
                  <input id="password" v-model="form.Password" class="form-control" type="password" />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="email" rules="required|email" tag="div">
              <div class="row">
                <label class="col-md-4" for="email">E-mail Address</label>
                <div class="col-md-8">
                  <input id="email" v-model="form.Email" class="form-control" type="text" />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="google 2FA" rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="google2FA">Google Auth(2FA)</label>
                <div class="col-md-8">
                  <input id="google2FA" v-model="form.Google2FA" class="form-control" readonly type="text" />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <div class="form-group">
              <div class="row">
                <div class="col-md-offset-4 col-md-8 text-center">
                  <p class="mb-0 pointer" @click="getTotp('GenerateSecurityID')">Reset 2FA Code</p>
                  <img :src="qrCode" alt="" />
                  <p v-if="!totp" class="mb-0 pointer" @click="getTotp('GenerateTOTP')">
                    <span>Click to test 2FA Code<br />Only set 2FA if numbers match</span>
                  </p>
                  <div v-if="totp" class="pointer">
                    <span>Test 2FA, result: {{ totp }}</span>
                    <div class="progress" style="height: 2px; width: 200px; margin: 8px auto;">
                      <div :aria-valuenow="progress" :style="'width:' + progress + '%'" aria-valuemax="100"
                        aria-valuemin="0" class="progress-bar" role="progressbar"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="google 2FA" rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="google2FAEnable">Google 2FA On/Off</label>
                <div class="col-md-8">
                  <select id="google2FAEnable" v-model="form.Google2FAEnabled" class="form-control">
                    <option value="false">Off</option>
                    <option value="true">On</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="locale" rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="locale">Locale</label>
                <div class="col-md-8">
                  <select id="locale" v-model="form.Locale" class="form-control">
                    <option :value="locale.key" v-for="(locale, index) in locales" :key="index">{{ locale.value }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <div class="text-right">
              <button type="submit" class="btn btn-primary btn-flat" :disabled="isLoading">
                Update
                <span v-if="isLoading">
                  <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                </span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { generatePayload, logout, saveNotification } from "@/lib/utils";
import { SAVE_ACCOUNT } from "@/store/keys";

export default {
  name: "AccountDetail",
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        ID: "",
        ShopTitle: "",
        Email: "",
        Password: "",
        Google2FA: "",
        Google2FAEnabled: "",
        Locale: "",
      },
      totp: null,
      progress: 100,
      timer: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "locales",
    ]),
    qrCode() {
      return `https://durpbox.com/qr/chart?size=250&text=otpauth://totp/Cointopay(${this.user.OwnerNickName})?secret=${this.user.Google2FA}&issuer=Cointopay`;
    },
  },
  watch: {
    user(val) {
      if (val) {
        this.updateValues();
      }
    },
  },
  methods: {
    updateValues() {
      if (this.user) {
        this.form = {
          ID: this.user.ID,
          ShopTitle: this.user.ShopTitle,
          Email: this.user.Email,
          Password: "",
          Google2FA: this.user.Google2FA,
          Google2FAEnabled: this.user.Google2FAEnabled,
          Locale: this.user.Locale,
        };
      }
    },
    getTotp(call) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          Call: call,
          MerchantID: this.user.ID,
          APIKey: this.user.APIKey,
          AltCoinID: -1,
          TOTPSecurity: this.form.Google2FA.replace(/ /g, ""),
          output: "json",
        },
      }).then(response => {
        if (call === "GenerateTOTP") {
          this.totp = response.data;
          // Reset code after 30 sec
          let counter = 0;
          this.timer = setInterval(() => {
            counter++;
            if (counter >= 30) {
              counter = 0;
              this.getTotp("GenerateTOTP");
              clearInterval(this.timer);
            }
            // Calculate progress
            this.progress = 100 - ((100 / 30) * counter);
          }, 1000);
        } else if (call === "GenerateSecurityID") {
          this.user.Google2FA = response.data.replace(/ /g, "").replace(/'/g, "");
          this.form.Google2FA = this.user.Google2FA;
          if (this.timer) {
            clearInterval(this.timer);
            this.totp = null;
          }
        }
      }).catch(error => {
        console.log(error.response);
      });
    },
    onSubmit() {
      const form = JSON.parse(JSON.stringify(this.form));
      let payload = { ...this.user };
      payload.ShopTitle = form.ShopTitle;
      payload.Email = form.Email;
      payload.Password = form.Password;
      payload.Google2FA = form.Google2FA;
      payload.Google2FAEnabled = form.Google2FAEnabled;
      payload.Locale = form.Locale;
      payload = generatePayload(payload);
      this.isLoading = true;
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$emit("refresh", this.user.ID);
          this.$toast.fire("", "Account detail updated successfully", "success");
          saveNotification("Account detail updated successfully");
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
  },
  mounted() {
    this.updateValues();
  },
};
</script>

<style scoped></style>
