<template>
  <div id="bsModalCryptoWithdraw" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="amount" rules="required" tag="div">
                <label for="amount">Amount</label>
                <input id="amount" v-model="form.Amount" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="merchant id" rules="required" tag="div">
                <label for="merchantID">Merchant</label>
                <select id="merchantID" v-model="form.MerchantID" class="form-control" @change="populate()">
                  <option v-for="(merchant, index) in merchants" :key="index" :value="merchant.id">{{ merchant.name }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="coin address" rules="required" tag="div">
                <label for="coinAddress">Coin Address</label>
                <input id="coinAddress" v-model="form.coinAddress" @keyup="trimValue('coinAddress')" class="form-control"
                  type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="tagging === 1" v-slot="{ errors }" class="form-group" name="address tag"
                tag="div">
                <label v-if="tagging === 1" for="tag">Address Tag</label>
                <input v-if="tagging === 1" id="tag" v-model="form.tag" @keyup="trimValue('tag')" class="form-control"
                  type="text" />
                <span v-if="tagging === 1" class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="altCoinID" rules="required" tag="div">
                <label for="altCoinID">AltCoinID</label>
                <select id="altCoinID" v-model="form.AltCoinID" class="form-control no-pointer-events">
                  <option v-for="(altCoin, index) in onlineAltCoins" :key="index" :value="altCoin.ID">
                    {{ altCoin.Name + ' - ' + altCoin.LongName }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="charging method" rules="required"
                tag="div">
                <label for="chargingMethod">Charging Method</label>
                <input id="chargingMethod" v-model="form.ChargingMethod" class="form-control" readonly type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="payout month" rules="required" tag="div">
                <label for="payoutMonth">Payout Month</label>
                <select id="payoutMonth" v-model="form.PayoutMonth" class="form-control">
                  <option v-for="(month, index) in months" :key="index" :value="month.id">{{ month.name }}</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="total transactions" rules="required"
                tag="div">
                <label for="totalTransactions">Total Transactions</label>
                <input id="totalTransactions" v-model="form.TransactionTotal" class="form-control" readonly type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="api key" rules="required" tag="div">
                <label for="apiKey">API Key</label>
                <input id="apiKey" v-model="form.APIKey" class="form-control" readonly type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="customer reference" rules="required"
                tag="div">
                <label for="customerReference">Customer Reference</label>
                <input id="customerReference" v-model="form.CustomerReferenceNr" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Crypto withdraw</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toFixed, saveNotification } from "@/lib/utils";

export default {
  name: "CryptoWithdrawModal",
  data() {
    return {
      isLoading: false,
      title: "",
      tagging: 0,
      data: null,
      merchants: [],
      form: {
        MerchantID: "",
        Amount: "",
        coinAddress: "",
        AltCoinID: "",
        ChargingMethod: "",
        PayoutMonth: "",
        TransactionTotal: "",
        APIKey: "",
        CustomerReferenceNr: "",
        tag: "",
      },
      months: [
        {
          id: 1,
          name: "January",
        },
        {
          id: 2,
          name: "February",
        },
        {
          id: 3,
          name: "March",
        },
        {
          id: 4,
          name: "April",
        },
        {
          id: 5,
          name: "May",
        },
        {
          id: 6,
          name: "June",
        },
        {
          id: 7,
          name: "July",
        },
        {
          id: 8,
          name: "August",
        },
        {
          id: 9,
          name: "September",
        },
        {
          id: 10,
          name: "October",
        },
        {
          id: 11,
          name: "November",
        },
        {
          id: 12,
          name: "December",
        },
      ],
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "onlineAltCoins",
    ]),
  },
  methods: {
    trimValue(key) {
      this.form[key] = this.form[key] ? this.form[key].trim() : "";
    },
    confirm() {
      this.confirmHook();
      this.close();
    },
    onSubmit() {
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/GetSendToAddress`, this.qs.stringify(payload), {}).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$toast.fire("", response.data, "success");
          saveNotification(response.data);
          // Close modal
          this.confirm();
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data, "error");
        saveNotification(error.data);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      this.form = {
        MerchantID: "",
        Amount: "",
        coinAddress: "",
        AltCoinID: "",
        ChargingMethod: "",
        PayoutMonth: "",
        TransactionTotal: "",
        APIKey: "",
        CustomerReferenceNr: "",
        tag: "",
      };
      this.$nextTick(() => this.$refs.observer.reset());
      $("#bsModalCryptoWithdraw").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("crypto-withdraw-modal", (args) => {
      this.title = args.title;
      this.data = args.data;
      const month = (new Date()).getMonth() + 1;
      if (this.data) {
        this.tagging = this.data.Tag;
        this.merchants = [];
        this.merchants.push({
          id: this.data.MerchantID,
          name: this.data.ShopTitle,
        });
        this.form = {
          MerchantID: this.data.MerchantID,
          Amount: toFixed(this.data.Balance),
          coinAddress: "",
          ChargingMethod: "fixed-fee",
          PayoutMonth: month,
          AltCoinID: this.data.ID,
          TransactionTotal: 1,
          APIKey: this.data.APIKey,
          CustomerReferenceNr: "Automated Payout",
          tag: "",
          output: "json",
        };
        if (this.data.AddressBookCrypto !== "NA") {
          if (this.data.AddressBookCrypto.altCoinID === this.data.ID) {
            this.form.coinAddress = this.data.AddressBookCrypto.address;
          }
        }
      }
      this.confirmHook = args.confirm;
      $("#bsModalCryptoWithdraw").modal("show");
    });
  },
};
</script>

<style scoped lang="scss">
.no-pointer-events {
  pointer-events: none;
}
</style>
