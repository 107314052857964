<template>
  <div id="bsModalBankPayout" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div v-if="data">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider v-slot="{ errors }" class="form-group" name="amount" rules="required" tag="div">
                  <div class="flex align-items-center justify-between">
                    <div>
                      <label for="amount">
                        Amount
                        <span v-if="fiatAmount !== null"> - {{ fiatCurrency + ' ' + fiatAmount }}</span>
                      </label>
                    </div>
                    <div>
                      <span v-if="data.TotalBalance > 0.00000001"
                            class="pointer" @click="payoutAllBalance()">Payout all balance
                      </span>
                    </div>
                  </div>
                  <input id="amount" v-model="form.amount" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="form-group">
                  <label>Payout Information</label>
                  <div v-if="!selectedAddress">
                    Payout Schema: {{ user.PayoutSchema }}
                    <div v-if="schemaType === 'paypal' || schemaType === 'payeer'">
                      <p>Email: {{ user.PayoutTo }}</p>
                    </div>
                    <div v-if="schemaType === 'bank'">
                      <p class="mb-0">Account Holder Name: {{ user.PayoutName }}</p>
                      <p class="mb-0">IBAN/Account Number: {{ user.PayoutIBAN }}</p>
                      <p>BIC/Swift Code: {{ user.PayoutBIC }}</p>
                    </div>
                  </div>
                  <div v-if="selectedAddress !== null" class="row">
                    <div class="col-sm-9">
                      <p v-for="(item, key) in selectedAddress" :key="key" class="mb-0">
                        {{ format(key) + ': ' + item }}</p>
                    </div>
                    <div class="col-sm-3 text-right">
                      <button class="btn btn-danger btn-sm" type="button"
                              @click="removeSelectedAddress()"> Remove
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <a class="btn btn-primary btn-flat" href="javascript:void(0)"
                         @click="openBankAddressBook()">Bank Address Book</a>
                    </div>
                  </div>
                  <div class="col-md-6 text-right">
                    <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                      <span v-if="!isLoading">Pay Out</span>
                      <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toFixed, saveNotification } from "@/lib/utils";

export default {
  name: "BankPayoutModal",
  data() {
    return {
      isLoading: false,
      title: "",
      data: null,
      user: null,
      form: {
        amount: "",
      },
      addressesList: {
        tranferwise: [],
        crypto: [],
      },
      selectedAddress: null,
      fiatValue: null,
      fiatAmount: null,
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "onlineAltCoins",
      "transferWiseConfig",
    ]),
    fiatCurrency() {
      let currencySymbol = this.data.Fiat;
      const inputCurrencies = this.onlineAltCoins;
      if (inputCurrencies) {
        const currency = this._.find(inputCurrencies, { ShortName: currencySymbol });
        if (currency) {
          currencySymbol = currency.LongName;
        }
      }
      return currencySymbol;
    },
    schemaType() {
      const payoutSchema = this.user ? this.user.PayoutSchema : null;
      if (payoutSchema === "Keep with Cointopay") {
        return "";
      }
      let type = "";
      const banks = ["Pay out EUR Bank", "Pay out USD Bank", "Pay out GBP Bank"];
      if (banks.indexOf(payoutSchema) !== -1) {
        type = "bank";
      } else if (payoutSchema === "Payout Payeer") {
        type = "payeer";
      } else if (payoutSchema === "Payout Paypal") {
        type = "paypal";
      } else if (payoutSchema === "Pay out to bank address book default") {
        type = "address-book-bank";
      } else if (payoutSchema === "Pay out to crypto address book default") {
        type = "address-book-crypto";
      }
      return type;
    },
  },
  watch: {
    "form.amount"(value) {
      this.fiatAmount = toFixed((1 / this.fiatValue) * value);
    },
  },
  methods: {
    trimValue(key) {
      this.form[key] = this.form[key] ? this.form[key].trim() : "";
    },
    confirm() {
      this.confirmHook();
      this.close();
    },
    fiatLookup(amount) {
      if (amount) {
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const defaultPreferredCurrency = this.data.Fiat ? this.data.Fiat : "EUR";
        this.$http.get(`${apiBaseUrl}/v2REAPI`, {
          params: {
            Call: "Fiatlookup",
            MerchantID: this.user.ID,
            APIKey: this.user.APIKey,
            AltCoinID: this.data.AltCoinID,
            Amount: amount,
            FiatLookup: 1,
            FiatMoney: defaultPreferredCurrency,
            output: "json",
          },
        }).then(response => {
          if (response.data && response.data.length > 0) {
            this.fiatValue = response.data[0].OutputValue;
          }
        }).catch(error => {
          console.log(error);
        });
      }
    },
    payoutAllBalance() {
      this.form.amount = toFixed(this.data.TotalBalance);
    },
    getDefaultAddress() {
      Object.keys(this.addressesList.transferwise).forEach(currency => {
        const selectedAddr = this._.find(this.addressesList.transferwise[currency], { default: true });
        if (selectedAddr) {
          this.selectAddress(selectedAddr, currency);
        }
      });
    },
    openBankAddressBook() {
      this.$bus.$emit("bank-address-book-modal", {
        title: "Bank Address Book",
        data: this.user,
        isSelector: true,
        confirm: (address) => {
          if (address) {
            this.selectAddress(address);
          }
        },
      });
    },
    selectAddress(selectedAddr, currency = null) {
      if (!currency) {
        currency = selectedAddr.currency;
      }
      let obj = {
        targetCurrency: selectedAddr.currency,
        accountHolderName: selectedAddr.accountHolderName,
        legalType: selectedAddr.legalType ? selectedAddr.legalType : selectedAddr.details.legalType,
        targetLegalType: selectedAddr.targetLegalType,
      };
      if (selectedAddr.details.accountType) {
        obj = Object.assign(obj, {
          accountType: selectedAddr.details.accountType,
        });
      }
      let currenciesConfig = JSON.stringify(this.transferWiseConfig);
      currenciesConfig = JSON.parse(currenciesConfig);
      const config = this._.find(currenciesConfig, { currency: currency });
      const required = config.required.split(",");
      required.forEach(param => {
        const value = (selectedAddr.details[param])
          ? selectedAddr.details[param] : selectedAddr.details.address[param];
        obj = Object.assign(obj, { [param]: value });
      });
      this.selectedAddress = obj;
    },
    format(key) {
      const labels = {
        targetCurrency: "Target currency",
        accountHolderName: "Account holder name",
        legalType: "Legal type from",
        targetLegalType: "Legal type to",
        taxId: "Tax ID",
        accountNumber: "Account number",
        bsbCode: "Bsb code",
        ifscCode: "IFSC code",
        country: "Country",
        city: "City",
        postalCode: "Postal code",
        firstLine: "Address",
        abartn: "Routing number",
        bankCode: "Bank code",
        branchCode: "Branch code",
        phoneNumber: "Phone number",
        institutionNumber: "Institution no.",
        transitNumber: "Transit no.",
        rut: "Rut number",
        cardNumber: "Card number",
        prefix: "Prefix",
        sortCode: "Sort code",
        clabe: "Clabe",
        cpf: "Tax registration number (CPF)",
        swiftCode: "Swift code",
        idDocumentType: "ID document type",
        idDocumentNumber: "ID document type",
      };
      return (labels[key]) ? labels[key] : key;
    },
    onSubmit() {
      this.isLoading = true;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      let payload = {
        Call: "FiatPayout",
        MerchantID: this.user.ID,
        APIKey: this.user.APIKey,
        AltCoinID: this.data.AltCoinID,
        Amount: this.form.amount,
        output: "json",
      };
      if (this.selectedAddress != null) {
        payload = Object.assign(payload, this.selectedAddress);
      } else if (this.schemaType === "bank") {
        payload = Object.assign(payload, {
          accountHolderName: this.user.PayoutName,
          accountNumber: this.user.PayoutIBAN,
          bankCode: this.user.PayoutBIC,
        });
      }
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          if (typeof response.data === "string") {
            if (response.data.toLowerCase() === "ok") {
              this.selectedAddress = null;
              this.$toast.fire("", response.data.replace(/^\w/, c => c.toUpperCase()), "success");
              saveNotification(response.data.replace(/^\w/, c => c.toUpperCase()));
              this.confirm();
            } else {
              this.$toast.fire("error", response.data.replace(/^\w/, c => c.toUpperCase()), "error");
              saveNotification(response.data.replace(/^\w/, c => c.toUpperCase()));
            }
          }
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("error", error.data, "error");
      });
    },
    removeSelectedAddress() {
      this.selectedAddress = null;
      if (this.schemaType === "address-book-bank") {
        this.getDefaultAddress();
      }
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      this.form = {
        amount: "",
      };
      this.$nextTick(() => this.$refs.observer.reset());
      $("#bsModalBankPayout").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("bank-payout-modal", (args) => {
      this.title = args.title;
      this.data = args.record;
      this.user = args.user;
      if (this.user) {
        this.addressesList = this.user.AddressBook;
      }
      this.confirmHook = args.confirm;
      this.fiatLookup(1);
      $("#bsModalBankPayout").modal("show");
    });
  },
};
</script>

<style scoped lang="scss">
.no-pointer-events {
  pointer-events: none;
}
</style>
