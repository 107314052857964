<template>
  <div id="bsModalSwapModal" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="altCoinID" rules="required" tag="div">
                <label for="swapTargetAltCoinID">Target AltCoinID</label>
                <v-select id="swapTargetAltCoinID" v-model="form.TargetAltCoinID" :options="onlineAltCoins"
                  :reduce="coin => coin.ID" label="Name"></v-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="amount" rules="required" tag="div">
                <div class="flex align-items-center justify-between">
                  <label for="crypto-deposit-amount">Amount - {{ sourceCoin.LongName }}</label>
                  <a @click="updateAmount()" v-if="form.TargetAltCoinID">All Amount</a>
                </div>
                <input id="crypto-deposit-amount" v-model="form.Amount" class="form-control" type="text"
                  @keyup="calculate('amount')" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="targetAmount" tag="div"
                v-if="form.TargetAltCoinID">
                <label for="crypto-amount">Amount - {{ targetCoin.LongName }}</label>
                <input id="crypto-amount" v-model="targetAmount" class="form-control" type="text"
                  @keyup="calculate('targetAmount')" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Swap</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveNotification, toFixed } from "@/lib/utils";
import find from "lodash/find";

export default {
  name: "SwapModal",
  data() {
    return {
      isLoading: false,
      title: "",
      data: null,
      targetAmount: "",
      fiatValue: 0,
      isReverse: false,
      form: {
        Call: "SWAP",
        MerchantID: "",
        APIKey: "",
        AltCoinID: "",
        TargetAltCoinID: "",
        Amount: "",
        V2: "",
        output: "json",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "onlineAltCoins",
    ]),
    sourceCoin() {
      if (this.form.AltCoinID) {
        const sourceCoin = find(this.onlineAltCoins, { ID: this.form.AltCoinID });
        if (sourceCoin) {
          return sourceCoin;
        }
      }
      return "";
    },
    targetCoin() {
      if (this.form.TargetAltCoinID) {
        const targetCoin = find(this.onlineAltCoins, { ID: this.form.TargetAltCoinID });
        if (targetCoin) {
          return targetCoin;
        }
      }
      return "";
    },
  },
  watch: {
    "form.TargetAltCoinID"(newVal, oldval) {
      this.isReverse = false;
      this.fiatLookup();
    },
  },
  methods: {
    confirm() {
      this.confirmHook();
      this.close();
    },
    fiatLookup() {
      if (!this.form.TargetAltCoinID) {
        return;
      }
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "Fiatlookup",
          MerchantID: this.form.MerchantID,
          APIKey: this.form.APIKey,
          AltCoinID: this.isReverse ? this.targetCoin.ID : this.form.AltCoinID,
          Amount: 1,
          FiatLookup: 0,
          FiatMoney: this.isReverse ? this.sourceCoin.ShortName : this.targetCoin.ShortName,
          output: "json",
        },
      }).then(response => {
        if (response.data && response.data.length > 0) {
          this.fiatValue = Number(response.data[0].OutputValue);
          if (this.isReverse) {
            this.targetAmount = toFixed(Number(this.form.Amount) / this.fiatValue);
          } else {
            this.targetAmount = toFixed(this.fiatValue * Number(this.form.Amount));
          }
        } else {
          this.isReverse = true;
          this.fiatLookup(true);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    updateAmount() {
      this.form.Amount = this.data.Amount;
      this.calculate("amount");
    },
    calculate(key) {
      if (key === "amount") {
        if (this.isReverse) {
          this.targetAmount = toFixed(Number(this.form.Amount) / this.fiatValue);
        } else {
          this.targetAmount = toFixed(this.fiatValue * Number(this.form.Amount));
        }
      } else {
        if (this.isReverse) {
          this.form.Amount = toFixed(Number(this.targetAmount) * this.fiatValue);
        } else {
          this.form.Amount = toFixed(Number(this.targetAmount) / Number(this.fiatValue));
        }
      }
    },
    onSubmit() {
      this.isLoading = true;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      let payload = {
        Call: "COIN-ON",
        MerchantID: this.form.MerchantID,
        APIKey: this.form.APIKey,
        AltCoinID: this.form.TargetAltCoinID,
        output: "json",
      };
      // Enable coin
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        if (response.status === 200 && response.data.toLowerCase() === "ok") {
          payload = JSON.parse(JSON.stringify(this.form));
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
          }).then(response => {
            this.isLoading = false;
            if (response.status === 200 && response.data.result === "success") {
              this.$toast.fire("", response.data.message, "success");
            } else {
              this.$toast.fire("", response.data.message, "error");
            }
            this.confirm();
            saveNotification(response.data.message);
          }).catch(error => {
            this.isLoading = false;
            console.log(error);
          });
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    reset() {
      this.form = {
        Call: "SWAP",
        MerchantID: "",
        APIKey: "",
        AltCoinID: "",
        TargetAltCoinID: "",
        Amount: "",
        V2: "",
        output: "json",
      };
      this.$nextTick(() => this.$refs.observer.reset());
    },
    close() {
      this.reset();
      $("#bsModalSwapModal").modal("hide");
    },
  },
  mounted() {
    this.inputCurrencies = this.onlineAltCoins;
    this.$bus.$on("swap-modal", (args) => {
      this.title = args.title;
      this.data = args.data;
      // Populate form
      this.form = {
        ...this.form,
        MerchantID: this.data.MerchantID,
        APIKey: this.data.APIKey,
        AltCoinID: this.data.ID,
        Amount: this.data.Amount,
        TargetAltCoinID: this.data.isAutoSwap ? this.data.PreferredCoinID : "",
      };
      this.isReverse = false;
      this.confirmHook = args.confirm;
      $("#bsModalSwapModal").modal("show");
    });
  },
};
</script>

<style scoped></style>
