<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <!-- Essential -->
            <div class="box">
              <label class="title">Essential</label>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="payment options" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4">Invoice Page tabs</label>
                  <div class="col-md-8">
                    <div class="d-flex align-items-center">
                      <label class="mr-8">
                        <input v-model="form.paymentOptions" :checked="isChecked('crypto')" type="checkbox"
                          value="crypto">
                        <span class="ml-8">Crypto</span>
                      </label>
                      <label class="mr-8">
                        <input v-model="form.paymentOptions" :checked="isChecked('instant')" type="checkbox"
                          value="instant">
                        <span class="ml-8">Instant</span>
                      </label>
                      <label class="mr-8">
                        <input v-model="form.paymentOptions" :checked="isChecked('fiat')" type="checkbox" value="fiat">
                        <span class="ml-8">Fiat</span>
                      </label>
                      ({{ form.paymentOptions.join(', ') }})
                    </div>
                    <div class="text-danger">{{ errors[0] }}</div>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="risk profile" rules="required" tag="div">
                <div class="row">
                  <label class="col-md-4" for="riskProfile">Risk Profile</label>
                  <div class="col-md-8">
                    <select id="riskProfile" v-model="form.RiskProfileID" class="form-control">
                      <option v-for="(riskProfile, index) in riskProfiles" :key="index" :value="riskProfile.ID">
                        {{ riskProfile.Description }}
                      </option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="show international cc" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="showInternationalCC">Enable CreditCard</label>
                  <div class="col-md-8">
                    <select id="showInternationalCC" v-model="form.ShowInternationalCC" class="form-control">
                      <option v-for="mCC in merchantCC" :key="mCC.PrimaryID" :value="mCC.UsedID">
                        {{ typeToName(mCC.Merchant_CC_Type) }} - {{ mCC.Name }}
                      </option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="enable bank" rules="required" tag="div">
                <div class="row">
                  <label class="col-md-4">Enable Bank</label>
                  <div class="col-md-8">
                    <select v-model="form.BankEnable" class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On Dynamic (888 group)</option>
                      <option value="2">On Fixed (999 group)</option>
                      <option v-for="mCC in merchantCC" :key="mCC.PrimaryID" :value="mCC.UsedID">
                        {{ typeToName(mCC.Merchant_CC_Type) }} - {{ mCC.Name }}
                      </option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="disable checkout" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="disableCheckout">Disable Checkout</label>
                  <div class="col-md-8">
                    <select id="disableCheckout" v-model="form.DisableCheckout" class="form-control">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="disable payout" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="disablePayout">Disable Payout</label>
                  <div class="col-md-8">
                    <select id="disablePayout" v-model="form.DisablePayout" class="form-control">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="master transaction function"
                rules="required" tag="div">
                <div class="row">
                  <label class="col-md-4" for="masterTransactionFunction">Master Transaction Function</label>
                  <div class="col-md-8">
                    <select id="masterTransactionFunction" v-model="form.MasterTransactionFunction"
                      class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="subscription mode" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="subscriptionMode">Subscription Mode</label>
                  <div class="col-md-8">
                    <select id="subscriptionMode" v-model="form.SubscriptionMode" class="form-control">
                      <option value="0">0</option>
                      <option value="1">1</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="crm mode" rules="required" tag="div">
                <div class="row">
                  <label class="col-md-4" for="crmMode">CRM Mode</label>
                  <div class="col-md-8">
                    <select id="crmMode" v-model="form.CRMMode" class="form-control">
                      <option value="0">0</option>
                      <option value="1">1</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <!-- Communication -->
            <div class="box">
              <label class="title">Communication</label>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="email notifications" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="EmailNotifications">Email Notifications</label>
                  <div class="col-md-8">
                    <select id="EmailNotifications" v-model="form.EmailNotifications" class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="customer email notifications"
                rules="required" tag="div">
                <div class="row">
                  <label class="col-md-4" for="customerEmailNotifications">Customer Email Notifications</label>
                  <div class="col-md-8">
                    <select id="customerEmailNotifications" v-model="form.EmailNotificationsCustomer"
                      class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="SendAllConfirmationLink" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="sendAllConfirmationLink">Send All Confirmation Link</label>
                  <div class="col-md-8">
                    <select id="sendAllConfirmationLink" v-model="form.SendAllConfirmationLink" class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <!-- Options -->
            <div class="box">
              <label class="title">Options</label>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="unique customer ref" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="crmMode">Unique Customer Ref</label>
                  <div class="col-md-8">
                    <select id="crmMode" v-model="form.UniqueCustomerRef" class="form-control">
                      <option value="0">0</option>
                      <option value="1">1</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="show bunq link" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4">Show Bunq Link</label>
                  <div class="col-md-8">
                    <select id="show-bunq-link" v-model="form.ShowBunqLink" class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On</option>
                      <option value="444">Dynamic</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="enable paypal" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4">Enable PayPal</label>
                  <div class="col-md-8">
                    <select v-model="form.PayPalEnable" class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="enable payeer" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4">Enable Payeer</label>
                  <div class="col-md-8">
                    <select v-model="form.PayeerEnable" class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="max LTR" rules="required|min:1|max:50"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="maxLTR">Max LTR</label>
                  <div class="col-md-8">
                    <input id="maxLTR" v-model="form.MaxLTR" class="form-control" max="50" min="1" type="number" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="referral" rules="required|numeric"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="referral">Referral</label>
                  <div class="col-md-8">
                    <input id="referral" v-model="form.Referral" class="form-control" type="text" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <!-- Extras -->
            <div class="box">
              <label class="title">Extras</label>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="RedirectNewMerchantID"
                rules="required|numeric" tag="div">
                <div class="row">
                  <label class="col-md-4" for="redirectNewMerchantID">Redirect to MerchantID</label>
                  <div class="col-md-8">
                    <input id="redirectNewMerchantID" v-model="form.RedirectNewMerchantID" class="form-control"
                      type="text" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="life time member" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="lifeTimeMember">Life Time Member</label>
                  <div class="col-md-8">
                    <select id="lifeTimeMember" v-model="form.LifetimeMember" class="form-control">
                      <option value="-1">Off</option>
                      <option value="0">On</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="autonomy" rules="required" tag="div">
                <div class="row">
                  <label class="col-md-4" for="autonomy">Autonomy</label>
                  <div class="col-md-8">
                    <select id="autonomy" v-model="form.Autonomy" class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group" name="exact payout" rules="required" tag="div">
                <div class="row">
                  <label class="col-md-4">Exact Payout</label>
                  <div class="col-md-8">
                    <select v-model="form.ExactPayout" class="form-control">
                      <option value="0">Off</option>
                      <option value="1">On</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </div>

            <div class="text-right">
              <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                Update
                <span v-if="isLoading">
                  <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                </span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { generatePayload, logout, saveNotification } from "@/lib/utils";
import { SAVE_ACCOUNT } from "@/store/keys";

export default {
  name: "AdminConfig",
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      riskProfiles: [],
      merchantCC: [],
      form: {
        paymentOptions: [],
        DisableCheckout: "",
        DisablePayout: "",
        LifetimeMember: "",
        MaxLTR: "",
        RiskProfileID: "",
        ShowInternationalCC: "",
        Referral: "",
        MasterTransactionFunction: "",
        EmailNotifications: "",
        EmailNotificationsCustomer: "",
        Autonomy: "",
        ShowBunqLink: "",
        PayPalEnable: "",
        ExactPayout: "",
        BankEnable: "",
        PayeerEnable: "",
        RedirectNewMerchantID: "",
        SendAllConfirmationLink: "",
        SubscriptionMode: "",
        CRMMode: "",
        UniqueCustomerRef: "",
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
    ]),
  },
  watch: {
    user(val) {
      if (val) {
        this.updateValues();
      }
    },
  },
  methods: {
    listMerchantCC() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMerchantCC`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        if (response.status === 200) {
          this.merchantCC = response.data.data.filter(m => m.Active === 1);
        }
      }).catch(error => {
        this.$toast.fire("", error.response.message, "error");
      });
    },
    listMerchantRiskProfiles() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMerchantRiskProfile`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        if (response.status === 200) {
          this.riskProfiles = response.data.data;
        }
      }).catch(error => {
        this.$toast.fire("", error.response.message, "error");
      });
    },
    updateValues() {
      if (this.user) {
        this.form = {
          paymentOptions: this.user.PaymentOptions.trim().split(",").filter(Boolean).map(i => i.trim()),
          DisableCheckout: this.user.DisableCheckout ? 1 : 0,
          DisablePayout: this.user.DisablePayout ? 1 : 0,
          LifetimeMember: this.user.LifetimeMember,
          MaxLTR: this.user.MaxLTR,
          RiskProfileID: this.user.RiskProfileID,
          ShowInternationalCC: this.user.ShowInternationalCC,
          Referral: this.user.Referral,
          EmailNotifications: this.user.EmailNotifications,
          EmailNotificationsCustomer: this.user.EmailNotificationsCustomer,
          MasterTransactionFunction: this.user.MasterTransactionFunction,
          Autonomy: this.user.Autonomy ? 1 : 0,
          ShowBunqLink: this.user.ShowBunqLink,
          PayPalEnable: this.user.PayPalEnable ? 1 : 0,
          ExactPayout: this.user.ExactPayout ? 1 : 0,
          BankEnable: this.user.BankEnable,
          PayeerEnable: this.user.PayeerEnable,
          RedirectNewMerchantID: this.user.RedirectNewMerchantID,
          SendAllConfirmationLink: this.user.SendAllConfirmationLink,
          SubscriptionMode: this.user.SubscriptionMode,
          CRMMode: this.user.CRMMode,
          UniqueCustomerRef: this.user.UniqueCustomerRef,
        };
      }
    },
    isChecked(value) {
      return this._.findIndex(this.form.paymentOptions, value) !== -1;
    },
    typeToName(type) {
      let lbl = "";
      switch (type) {
        case 1:
          lbl = "Credit Card";
          break;
        case 2:
          lbl = "Payment Provider";
          break;
        case 3:
          lbl = "Bank";
          break;
      }
      return lbl;
    },
    onSubmit() {
      const form = JSON.parse(JSON.stringify(this.form));
      let payload = { ...this.user };
      payload.PaymentOptions = form.paymentOptions.length > 0 ? form.paymentOptions.map(i => i.trim()).join(",") : "";
      payload.RiskProfileID = form.RiskProfileID;
      payload.DisableCheckOut = form.DisableCheckout;
      payload.DisablePayout = form.DisablePayout;
      payload.LifetimeMember = form.LifetimeMember;
      payload.MaxLTR = form.MaxLTR;
      payload.ShowInternationalCC = form.ShowInternationalCC;
      payload.Referral = form.Referral;
      payload.MasterTransactionFunction = form.MasterTransactionFunction;
      payload.EmailNotifications = form.EmailNotifications;
      payload.EmailNotificationsCustomer = form.EmailNotificationsCustomer;
      payload.Autonomy = form.Autonomy;
      payload.PayPalEnable = form.PayPalEnable;
      payload.ExactPayout = form.ExactPayout;
      payload.BankEnable = form.BankEnable;
      payload.PayeerEnable = form.PayeerEnable;
      payload = generatePayload(payload);
      payload.ShowBunqLink = form.ShowBunqLink;
      payload.RedirectNewMerchantID = form.RedirectNewMerchantID;
      payload.SendAllConfirmationLink = form.SendAllConfirmationLink;
      payload.SubscriptionMode = form.SubscriptionMode;
      payload.CRMMode = form.CRMMode;
      payload.UniqueCustomerRef = form.UniqueCustomerRef;
      this.isLoading = true;
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$emit("refresh", this.user.ID);
          this.$toast.fire("", "Account detail updated successfully", "success");
          saveNotification("Account detail updated successfully");
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
  },
  mounted() {
    this.updateValues();
    this.listMerchantRiskProfiles();
    this.listMerchantCC();
  },
};
</script>

<style scoped lang="scss">
.box {
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
}
</style>
