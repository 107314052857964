<template>
  <div v-if="!isLoading">
    <div class="box bt-0">
      <div class="box-header">
        <div class="flex justify-between align-items-center">
          <h3 class="box-title nowrap">
            <span v-if="user">User Detail -- {{ user.OwnerNickName }}</span>
            <span v-else>Users</span>
          </h3>
          <div class="row" style="flex-grow: 1">
            <div class="col-md-9 text-right">
              <span v-if="searching" class="text-danger text-bold" style="display: inline-block; padding-top: 5px;">{{
    searching }}
              </span>
            </div>
            <div class="col-md-3">
              <input v-model="keyword" aria-label="Search" autofocus="autofocus" class="form-control" ref="searchInput"
                placeholder="Search..." type="text" @keyup="searchUsers($event)" />
            </div>
          </div>
        </div>
      </div>
      <template v-if="user">
        <va-tabs :padding="'padding: 10px 15px 20px'">
          <div v-if="isAuthorized('admin-commander')" id="account-detail" slot="content" :data-title="'Account Detail'">
            <account-detail :user="user" @refresh="getMerchantDetail"></account-detail>
          </div>
          <!-- User Detail -->
          <div id="user-detail" slot="content" :data-title="'User Detail'" class="active">
            <user-detail :hasBalance="hasBalance" :user="user" @updateFiles="loadImages()"
              @refresh="getMerchantDetail"></user-detail>
          </div>
          <!-- Balance Detail -->
          <div v-if="isAuthorized('admin-commander')" id="balance-detail" slot="content" :data-title="'Balance Detail'">
            <balance-detail :balanceList="balanceList" :user="user" @refreshBalance="getWalletsBalance"
              :isLoading="isBalanceLoading" @updateTransactions="getTransactions"></balance-detail>
          </div>
          <!-- Wallet Currencies -->
          <div v-if="isAuthorized('admin-commander')" id="wallet-currencies" slot="content"
            :data-title="'Wallet Currencies'">
            <wallet-currencies :user="user" @refresh="getMerchantDetail"></wallet-currencies>
          </div>
          <!-- Payout Detail -->
          <div v-if="isAuthorized('admin-commander')" id="payout-detail" slot="content" :data-title="'Payout Detail'">
            <payout-detail :user="user" @refresh="getMerchantDetail"></payout-detail>
          </div>
          <!-- Payment Urls -->
          <div v-if="isAuthorized('admin-commander')" id="payment-urls" slot="content" :data-title="'Payment Urls'">
            <payment-urls :user="user" @refresh="getMerchantDetail"></payment-urls>
          </div>
          <!-- Billing -->
          <div v-if="isAuthorized('admin-commander')" id="billing" slot="content" :data-title="'Billing'">
            <billing :user="user" @refresh="getMerchantDetail"></billing>
          </div>
          <!-- POS -->
          <div v-if="isAuthorized('admin-commander')" id="pos" slot="content" :data-title="'Point of Sale'">
            <pos :user="user" @refresh="getMerchantDetail"></pos>
          </div>
          <!-- Activity -->
          <div v-if="isAuthorized('admin-commander')" id="activity" slot="content" :data-title="'Activity'">
            <activity :user="user"></activity>
          </div>
          <!-- Transactions -->
          <div v-if="isAuthorized('view-transactions')" id="transactions" slot="content" :data-title="'Transactions'">
            <transactions :transactions="transactions" :user="user" :selected-year="year" @year="getTransactions">
            </transactions>
          </div>
          <!-- Admin Config -->
          <div v-if="isAuthorized('admin-commander')" id="admin-config" slot="content" :data-title="'Admin Config'">
            <admin-config :user="user" @refresh="getMerchantDetail"></admin-config>
          </div>
          <!-- Autonomy -->
          <div v-if="isAuthorized('admin-commander')" id="autonomy-tab" slot="content" :data-title="'Autonomy'">
            <autonomy v-if="isAutonomy" :user="user" @refresh="getMerchantDetail"></autonomy>
            <div v-else>Enable Autonomy in Admin Config tab</div>
          </div>
        </va-tabs>
      </template>
      <template v-else>
        <users :users="users" :meta="meta" @sort="sortUsers"></users>
      </template>
    </div>

    <!-- Bank Address Book -->
    <bank-address-book-modal></bank-address-book-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatData, isAuthorized, saveNotification } from "@/lib/utils";
import { START_LOADING, STOP_LOADING, EXCHANGE_LIST } from "@/store/keys";
import VaTabs from "@/components/VATabs";
import UserDetail from "@/components/merchant-detail/UserDetail";
import Activity from "@/components/merchant-detail/Activity";
import BalanceDetail from "@/components/merchant-detail/BalanceDetail";
import AccountDetail from "@/components/merchant-detail/AccountDetail";
import PaymentUrls from "@/components/merchant-detail/PaymentUrls";
import Billing from "@/components/merchant-detail/Billing";
import Pos from "@/components/merchant-detail/Pos";
import WalletCurrencies from "@/components/merchant-detail/WalletCurrencies";
import PayoutDetail from "@/components/merchant-detail/PayoutDetail";
import Transactions from "@/components/merchant-detail/Transactions";
import AdminConfig from "@/components/merchant-detail/AdminConfig";
import Autonomy from "@/components/merchant-detail/Autonomy";
import BankAddressBookModal from "@/components/merchant-detail/modals/BankAddressBookModal";
import Users from "../components/merchant-detail/Users";

export default {
  name: "MerchantDetail",
  components: {
    Users,
    Autonomy,
    AdminConfig,
    Transactions,
    PayoutDetail,
    WalletCurrencies,
    Pos,
    Billing,
    PaymentUrls,
    AccountDetail,
    VaTabs,
    UserDetail,
    BalanceDetail,
    Activity,
    BankAddressBookModal,
  },
  data() {
    return {
      user: null,
      loader: "",
      balanceList: [],
      hasBalance: false,
      transactions: [],
      socket: null,
      isCloseSocket: false,
      isBalanceLoading: false,
      year: new Date().getFullYear(),
      searching: "",
      keyword: "",
      timer: null,
      users: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "isLoading",
    ]),
    isAutonomy() {
      return this.user.Autonomy;
    },
  },
  watch: {
    $route: {
      handler(to) {
        setTimeout(() => {
          $("[href=\"" + to.hash + "\"]").tab("show");
        }, 500);
        if (to.hash === "#transactions") {
          this.getTransactions();
        }
        this.getMerchantDetail(to.params.id);
      },
      immediate: true,
    },
  },
  methods: {
    isAuthorized(p) {
      return isAuthorized(p);
    },
    searchUsers(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getUsers(1, this.keyword, "");
        }, 500);
      }
    },
    sortUsers(args) {
      this.order = args.order;
      this.sortKey = args.sortKey;
      this.getUsers(1, this.keyword);
    },
    getUsers(page = 1, keyword = "", role = "") {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListUsers`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: keyword ? keyword.trim() : "",
          role,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then(response => {
        this.searching = "";
        if (response.status === 200) {
          this.users = response.data.data.users;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
          this.user = null;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
      });
    },
    getMerchantDetail(id, isLoader = false) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=UserDetail`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          ID: id,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        if (response.status === 200) {
          this.user = Object.assign({}, response.data.data);
          if (this.user) {
            this.user = Object.assign({}, this.formatData(this.user));
            this.$nextTick(() => {
              this.$set(this.user, "Autonomy", response.data.data.Autonomy);
            });
            this.users = [];
            this.keyword = "";
            if (isLoader) {
              this.getWalletsBalance();
              this.getTransactions(this.year);
            }
            this.loadImages();
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getWalletsBalance() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=BalanceOverview`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id: this.user.ID,
        },
      }).then(response => {
        if (response.status === 200) {
          this.balanceList = response.data.data;
          this.hasBalance = this._.findIndex(this.balanceList, (record) => {
            return Number.parseFloat(record.TotalBalance, 10) > 0 || record.VTokenBalance > 0 || record.SellAmount > 0 || record.BuyAmount > 0;
          }) !== -1;
          const altcoinsList = [];
          this.balanceList.forEach(b => {
            altcoinsList.push(b.AltCoinID);
          });
        }
      }).catch(error => {
        if (error?.response?.message) {
          saveNotification(error.response.message);
        }
        console.log(error);
      });
    },
    loadImages() {
      this.loader = "images";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        POSDATA: "",
        Call: "SHOW",
        KeyData: "FILES_" + this.user.ID,
        MerchantID: this.user.ID,
        APIKey: this.user.APIKey,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/MarketAPI`, {
        params: payload,
      }).then(response => {
        this.loader = "";
        if (response.data !== "nothing found") {
          let files = {
            passport: "",
            avatar: "",
          };
          if (typeof response.data.avatar !== "undefined") {
            files = response.data;
            if (files.avatar && files.avatar.startsWith("https://cointopay.com")) {
              files.avatar = files.avatar.replace("https://cointopay.com", "https://app.cointopay.com");
              if (files.avatar.indexOf("/Cointopay") !== -1) {
                files.avatar = files.avatar.replace("/Cointopay", "");
              }
            }
            if (files.passport && files.passport.startsWith("https://cointopay.com")) {
              files.passport = files.passport.replace("https://cointopay.com", "https://app.cointopay.com");
              if (files.passport.indexOf("/Cointopay") !== -1) {
                files.passport = files.passport.replace("/Cointopay", "");
              }
            }
          }
          this.user = Object.assign({}, this.user, {
            files: files,
          });
        }
      }).catch(error => {
        this.loader = "";
        console.log(error.response);
      });
    },
    getTransactions(year = new Date().getFullYear()) {
      if (!this.user) {
        return;
      }
      this.year = year;
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListTransactions`, {
        params: {
          MerchantID: this.user.ID,
          year: year,
        },
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        if (response.status === 200) {
          this.transactions = response.data.data;
        }
      }).catch(error => {
        if (error && error.data && error.data.message) {
          this.$toast.fire("", error.data.message, "error");
        }
      });
    },
    formatData(data) {
      return formatData(data);
    },
    // Web Socket
    initSocket() {
      this.socket = new WebSocket("wss://wss.cointopay.com/balance");
      const self = this;
      this.socket.onmessage = (event) => {
        if (event) {
          if (event.data) {
            const parts = event.data.split(":");
            if (parts.length >= 4 && Object.keys(self.user).length > 0) {
              const id = Number.parseInt(parts[0], 10);
              if (id === self.user.ID) {
                self.getWalletsBalance();
              }
            }
          }
        }
      };
      this.socket.onclose = (event) => {
        if (!self.isCloseSocket) {
          self.initSocket();
        }
      };
    },
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getMerchantDetail(id, true);
    }
    this.$store.dispatch(EXCHANGE_LIST);
    this.initSocket();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
    if (this.socket) {
      this.isCloseSocket = true;
      this.socket.close();
    }
  },
};
</script>

<style lang="scss" scoped></style>
