var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"logo"}},[_vm._v("POS Logo")]),_c('div',{staticClass:"col-md-8"},[_c('input',{ref:"avatar",attrs:{"id":"logo","type":"file"},on:{"change":function($event){return _vm.uploadFile()}}}),(_vm.avatar)?_c('div',{staticClass:"mt-8"},[_c('silent-box',{attrs:{"image":_vm.img}})],1):_vm._e()])])]),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"social url","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"socialUrl"}},[_vm._v("Social URL")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.SocialURL),expression:"form.SocialURL"}],staticClass:"form-control",attrs:{"id":"socialUrl","type":"text"},domProps:{"value":(_vm.form.SocialURL)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "SocialURL", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"facebook","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"socialFacebook"}},[_vm._v("Facebook")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.SocialFacebook),expression:"form.SocialFacebook"}],staticClass:"form-control",attrs:{"id":"socialFacebook","type":"text"},domProps:{"value":(_vm.form.SocialFacebook)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "SocialFacebook", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"twitter","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"socialTwitter"}},[_vm._v("Twitter")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.SocialTwitter),expression:"form.SocialTwitter"}],staticClass:"form-control",attrs:{"id":"socialTwitter","type":"text"},domProps:{"value":(_vm.form.SocialTwitter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "SocialTwitter", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"linkedIn","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"socialLinkedIn"}},[_vm._v("Linked In")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.SocialLinkedIn),expression:"form.SocialLinkedIn"}],staticClass:"form-control",attrs:{"id":"socialLinkedIn","type":"text"},domProps:{"value":(_vm.form.SocialLinkedIn)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "SocialLinkedIn", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"reddit","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"socialReddit"}},[_vm._v("Reddit")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.SocialReddit),expression:"form.SocialReddit"}],staticClass:"form-control",attrs:{"id":"socialReddit","type":"text"},domProps:{"value":(_vm.form.SocialReddit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "SocialReddit", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-flat",attrs:{"type":"submit","disabled":_vm.isLoading}},[_vm._v(" Update "),(_vm.isLoading)?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin ml-8"})]):_vm._e()])])],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }