<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div v-if="!isEdit" class="table-responsive">
          <table class="table table-condensed table-striped mb-0">
            <tbody>
            <tr>
              <th colspan="2">
                <div class="flex align-items-center justify-between">
                  <span>Account Detail - ({{ user.AccountType }} Account)</span>
                  <div>
                    <router-link :to="{name: 'kyc', params: { id: user.ID}}" class="btn btn-primary btn-sm btn-flat mr-8"
                                 tag="a">
                      KYC
                    </router-link>
                    <button v-if="isAuthorized('admin-commander') && !isEdit" class="btn btn-primary btn-flat btn-sm"
                            @click="editForm()">Edit
                    </button>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <td style="min-width: 220px;width: 220px;">Merchant ID</td>
              <td>
                <span class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.ID)"></i>
                </span>
                {{ user.ID }}
              </td>
            </tr>
            <tr>
              <td>Owner Nick Name</td>
              <td>
                <span class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.OwnerNickName)"></i>
                </span>
                {{ user.OwnerNickName }}
              </td>
            </tr>
            <tr>
              <td>Shop Title/Organization</td>
              <td>
                <span class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.ShopTitle)"></i>
                </span>
                {{ user.ShopTitle }}
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>
                <span class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.Email)"></i>
                </span>
                {{ user.Email }}
              </td>
            </tr>
            <tr>
              <th colspan="2">
                <div class="flex align-items-center justify-between">
                  <span>Beneficiary Personal Information</span>
                  <a :href="generateGoogleAddress(user, 'user')" target="_blank"><i class="fa fa-globe"></i></a>
                </div>
              </th>
            </tr>
            <tr>
              <td style="min-width: 220px;width: 220px;">Beneficiary Full Name</td>
              <td>
                <span v-if="user.PayoutFullName" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutFullName)"></i>
                </span>
                {{ user.PayoutFullName }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary First Name</td>
              <td>
                <span v-if="user.PayoutFirstName" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutFirstName)"></i>
                </span>
                {{ user.PayoutFirstName }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary Last Name</td>
              <td>
                <span v-if="user.PayoutLastName" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutLastName)"></i>
                </span>
                {{ user.PayoutLastName }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary Birth Date</td>
              <td>
                <span v-if="!isEditableBirthDate || user.FinalVerification" class="pointer"
                      @click="launchEditBirthDateForm()">
                  <span v-if="user.PayoutBirthDate" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutBirthDate)"></i>
                  </span>
                  {{ user.PayoutBirthDate ? user.PayoutBirthDate : 'NA' }}
                </span>
                <div v-if="isEditableBirthDate && !user.FinalVerification" class="row">
                  <div class="col-md-4">
                    <ValidationObserver v-slot="{ handleSubmit }">
                      <form @submit.prevent="handleSubmit(updateBirthDate)">
                        <div class="flex">
                          <ValidationProvider v-slot="{ errors }" name="birth date" rules="required" tag="div">
                            <date-picker v-model="formBirthDate.BirthDate" :editable="false" format="YYYY-MM-DD"
                                         value-type="format"></date-picker>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <button class="btn btn-primary btn-flat btn-square ml-8" type="submit">
                            <svg v-if="loader !== 'updateBirthDate'" class="w-24 h-24" fill="none"
                                 stroke="currentColor"
                                 viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 13l4 4L19 7" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"></path>
                            </svg>
                            <i v-if="loader === 'updateBirthDate'" class="fa fa-circle-o-notch fa-spin"></i>
                          </button>
                          <button class="btn btn-square btn-danger btn-flat ml-8" type="button"
                                  @click="isEditableBirthDate = false">
                            <svg class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"></path>
                            </svg>
                          </button>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Beneficiary PEP</td>
              <td>
                <span class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PEP ? 1 : 0)"></i>
                </span>
                {{ user.PEP ? 'Yes' : 'No' }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary Address</td>
              <td>
                <span v-if="user.PayoutAddress" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutAddress)"></i>
                </span>
                {{ user.PayoutAddress }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary Postal Code</td>
              <td>
                <span v-if="user.PayoutPostalCode" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutPostalCode)"></i>
                </span>
                {{ user.PayoutPostalCode }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary City</td>
              <td>
                <span v-if="user.PayoutCity" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutCity)"></i>
                </span>
                {{ user.PayoutCity }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary State</td>
              <td>
                <span v-if="user.PayoutState" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutState)"></i>
                </span>
                {{ user.PayoutState }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary Country</td>
              <td>
                <span v-if="user.PayoutCountry" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutCountry)"></i>
                </span>
                {{ user.PayoutCountry }}
              </td>
            </tr>
            <template v-if="user.AccountType.toLowerCase() === 'business'">
              <tr>
                <th colspan="2">
                  <div class="flex align-items-center justify-between">
                    <span>Business Information</span>
                    <a :href="generateGoogleAddress(user, 'business')" target="_blank"><i class="fa fa-globe"></i></a>
                  </div>
                </th>
              </tr>
              <tr>
                <td>Business Name</td>
                <td>
                  <span v-if="user.PayoutBankName" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutBankName)"></i>
                  </span>
                  {{ user.PayoutBankName }}
                </td>
              </tr>
              <tr>
                <td>Business Address</td>
                <td>
                  <span v-if="user.PayoutBankAddress" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutBankAddress)"></i>
                  </span>
                  {{ user.PayoutBankAddress }}
                </td>
              </tr>
              <tr>
                <td>Business Postal Code</td>
                <td>
                  <span v-if="user.PayoutBankPostalCode" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutBankPostalCode)"></i>
                  </span>
                  {{ user.PayoutBankPostalCode }}
                </td>
              </tr>
              <tr>
                <td>Business City</td>
                <td>
                  <span v-if="user.PayoutBankCity" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutBankCity)"></i>
                  </span>
                  {{ user.PayoutBankCity }}
                </td>
              </tr>
              <tr>
                <td>Business State</td>
                <td>
                  <span v-if="user.PayoutBankState" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutBankState)"></i>
                  </span>
                  {{ user.PayoutBankState }}
                </td>
              </tr>
              <tr>
                <td>Business Country</td>
                <td>
                  <span v-if="user.PayoutBankCountry" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutBankCountry)"></i>
                  </span>
                  {{ user.PayoutBankCountry }}
                </td>
              </tr>
            </template>
            <tr>
              <th colspan="2">Beneficiary Identity Information</th>
            </tr>
            <tr>
              <td>Beneficiary Identity Type</td>
              <td>
                <span v-if="user.PayoutIdentityType" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutIdentityType)"></i>
                </span>
                {{ formatIdentityType(user.PayoutIdentityType) }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary Identity Card Number</td>
              <td>
                <span v-if="user.PayoutIdentityCardNr" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutIdentityCardNr)"></i>
                </span>
                {{ user.PayoutIdentityCardNr }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary Identity Issue Country</td>
              <td>
                <span v-if="user.PayoutIdentityCountry" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutIdentityCountry)"></i>
                </span>
                {{ user.PayoutIdentityCountry }}
              </td>
            </tr>
            <tr>
              <th colspan="2">Payout Information</th>
            </tr>
            <tr>
              <td>Payout Schema</td>
              <td>
                <span v-if="user.PayoutSchema" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutSchema)"></i>
                </span>
                {{ user.PayoutSchema }}
              </td>
            </tr>
            <template v-if="getPayoutSchemaType(user.PayoutSchema) === 'bank'">
              <tr>
                <td>Bank Account Name</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutName)"></i>
                  </span>
                  {{ user.PayoutName }}
                </td>
              </tr>
              <tr>
                <td>IBAN/Account Number</td>
                <td>
                  <span v-if="user.PayoutIBAN" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutIBAN)"></i>
                  </span>
                  {{ user.PayoutIBAN }}
                </td>
              </tr>
              <tr>
                <td>BIC/Swift/Routing code</td>
                <td>
                  <span v-if="user.PayoutBIC" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutBIC)"></i>
                  </span>
                  {{ user.PayoutBIC }}
                </td>
              </tr>
            </template>
            <template v-if="getPayoutSchemaType(user.PayoutSchema) === 'payeer'">
              <tr>
                <td>Paypal/Payeer</td>
                <td>
                  <span v-if="user.PayoutTo" class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.PayoutTo)"></i>
                  </span>
                  {{ user.PayoutTo }}
                </td>
              </tr>
            </template>
            <template v-if="getPayoutSchemaType(user.PayoutSchema) === 'address-book-bank'
                                && user.AddressBookBank && user.AddressBookBank !== 'NA'">
              <tr>
                <td>Currency</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.currency)"></i>
                  </span>
                  {{ user.AddressBookBank.currency }}
                </td>
              </tr>
              <tr>
                <td>Account Holder Name</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer"
                       @click="copyToClipboard(user.AddressBookBank.accountHolderName)"></i>
                  </span>
                  {{ user.AddressBookBank.accountHolderName }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.accountType">
                <td>Account Type</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.accountType)"></i>
                  </span>
                  {{ user.AddressBookBank.accountType.toLowerCase() }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.IBAN">
                <td>IBAN</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.IBAN)"></i>
                  </span>
                  {{ user.AddressBookBank.IBAN }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.accountNumber">
                <td>Account Number</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.accountNumber)"></i>
                  </span>
                  {{ user.AddressBookBank.accountNumber }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.taxId">
                <td>Tax Id: CUIL / CUIT</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.taxId)"></i>
                  </span>
                  {{ user.AddressBookBank.taxId }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.bsbCode">
                <td>BSC Code</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.bsbCode)"></i>
                  </span>
                  {{ user.AddressBookBank.bsbCode }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.bankCode">
                <td>Bank Code</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.bankCode)"></i>
                  </span>
                  {{ user.AddressBookBank.bankCode }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.branchCode">
                <td>Branch Code</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.branchCode)"></i>
                  </span>
                  {{ user.AddressBookBank.branchCode }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.cpf">
                <td>Recipient tax ID/CPF</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.cpf)"></i>
                  </span>
                  {{ user.AddressBookBank.cpf }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.phoneNumber">
                <td>Account Number</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.phoneNumber)"></i>
                  </span>
                  {{ user.AddressBookBank.phoneNumber }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.institutionNumber">
                <td>Institution Number</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer"
                       @click="copyToClipboard(user.AddressBookBank.institutionNumber)"></i>
                  </span>
                  {{ user.AddressBookBank.institutionNumber }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.transitCode">
                <td>Transit Number</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.transitCode)"></i>
                  </span>
                  {{ user.AddressBookBank.transitCode }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.rut">
                <td>Beneficiary's RUT number (Rol Único Tributario)</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.rut)"></i>
                  </span>
                  {{ user.AddressBookBank.rut }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.cardNumber">
                <td>UnionPay card number</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.cardNumber)"></i>
                  </span>
                  {{ user.AddressBookBank.cardNumber }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.sortCode">
                <td>Sort Code</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.sortCode)"></i>
                  </span>
                  {{ user.AddressBookBank.sortCode }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.ifscCode">
                <td>IFSC Code</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.ifscCode)"></i>
                  </span>
                  {{ user.AddressBookBank.ifscCode }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.clabe">
                <td>Recipient CLABE Number</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.clabe)"></i>
                  </span>
                  {{ user.AddressBookBank.clabe }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.swiftCode">
                <td>Swift Code</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.swiftCode)"></i>
                  </span>
                  {{ user.AddressBookBank.swiftCode }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.idDocumentType">
                <td>ID Document Type</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer"
                       @click="copyToClipboard(user.AddressBookBank.idDocumentType)"></i>
                  </span>
                  {{ user.AddressBookBank.idDocumentType }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.idDocumentNumber">
                <td>Identification Number</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer"
                       @click="copyToClipboard(user.AddressBookBank.idDocumentNumber)"></i>
                  </span>
                  {{ user.AddressBookBank.idDocumentNumber }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.russiaRegion">
                <td>Region</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.russiaRegion)"></i>
                  </span>
                  {{ user.AddressBookBank.russiaRegion }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.abartn">
                <td>Routing Number</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.abartn)"></i>
                  </span>
                  {{ user.AddressBookBank.abartn }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.firstLine">
                <td>Address</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.firstLine)"></i>
                  </span>
                  {{ user.AddressBookBank.firstLine }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.city">
                <td>City</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.city)"></i>
                  </span>
                  {{ user.AddressBookBank.city }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.state">
                <td>State</td>
                <td>
                  {{ user.AddressBookBank.state }}
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.state)"></i>
                  </span>
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.postCode">
                <td>Postal Code</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.postCode)"></i>
                  </span>
                  {{ user.AddressBookBank.postCode }}
                </td>
              </tr>
              <tr v-if="user.AddressBookBank.country">
                <td>Country</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookBank.country)"></i>
                  </span>
                  {{ user.AddressBookBank.country }}
                </td>
              </tr>
            </template>
            <template v-if="getPayoutSchemaType(user.PayoutSchema) === 'address-book-crypto'
                                && user.AddressBookCrypto && user.AddressBookCrypto !== 'NA'">
              <tr>
                <td>Crypto Currency</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookCrypto.altCoinID)"></i>
                  </span>
                  {{ user.AddressBookCrypto.altCoinID }}
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookCrypto.address)"></i>
                  </span>
                  {{ user.AddressBookCrypto.address }}
                </td>
              </tr>
            </template>
            <template>
              <tr>
                <th colspan="2">Income Source</th>
              </tr>
              <tr>
                <td>Income Source</td>
                <td>
                  <span class="mr-8">
                    <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.AddressBookCrypto.altCoinID)"></i>
                  </span>
                  {{ incomeSource.source }}
                  <span v-if="incomeSource.source.toLowerCase() === 'other'">&nbsp;({{ incomeSource.detail }})</span>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <!-- KYC -->
        <div v-if="isEdit">
          <ValidationObserver ref="observerCreate" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="account type" rules="required" tag="div">
                <label for="accountType">Business or Personal account</label>
                <select id="accountType" v-model="form.BusinessAccount" class="form-control">
                  <option value="0">Personal</option>
                  <option value="1">Business</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <label class="title">Beneficiary information</label>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="first name" rules="required" tag="div">
                <label for="firstName">Beneficiary First Name</label>
                <input id="firstName" v-model="form.PayoutFirstName" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="last name" rules="required" tag="div">
                <label for="lastName">Beneficiary Last Name</label>
                <input id="lastName" v-model="form.PayoutLastName" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="full name" rules="required" tag="div">
                <label for="fullName">Beneficiary Personal Full Name</label>
                <input id="fullName" v-model="form.PayoutFullName" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="birth date" rules="required" tag="div">
                <label for="birthDate">Beneficiary birth date</label>
                <div>
                  <date-picker id="birthDate" v-model="form.PayoutBirthDate" :editable="false"
                               format="YYYY-MM-DD" value-type="format"></date-picker>
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pep" rules="required" tag="div">
                <label>Are you a Politically Exposed Person (PEP)?</label>
                <div>
                  <label style="margin-right: 16px;">
                    <input v-model="form.PEP" name="pep" type="radio" value="1"> Yes
                  </label>
                  <label>
                    <input v-model="form.PEP" name="pep" type="radio" value="0"> No
                  </label>
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="address beneficiary"
                                  rules="required" tag="div">
                <label for="payoutAddress">Address Beneficiary</label>
                <input id="payoutAddress" v-model="form.PayoutAddress" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="postal code beneficiary" rules="required" tag="div">
                <label for="postalCodeBeneficiary">Postal code Beneficiary</label>
                <input id="postalCodeBeneficiary" v-model="form.PayoutPostalCode" class="form-control"
                       type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="city beneficiary" rules="required" tag="div">
                <label for="cityBeneficiary">City Beneficiary</label>
                <input id="cityBeneficiary" v-model="form.PayoutCity" class="form-control"
                       type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="state beneficiary" rules="required" tag="div">
                <label for="stateBeneficiary">State Beneficiary</label>
                <input id="stateBeneficiary" v-model="form.PayoutState" class="form-control"
                       type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="country beneficiary" rules="required" tag="div">
                <label for="countryBeneficiary">Country Beneficiary</label>
                <select id="countryBeneficiary" v-model="form.PayoutCountry" class="form-control">
                  <option v-for="(country, key) in countries" :key="key" :value="key">{{ country }}</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>

              <template v-if="form.BusinessAccount.toString() === '1'">
                <label class="title">Business information</label>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="business name" rules="required"
                                    tag="div">
                  <label for="businessName">Business Name</label>
                  <input id="businessName" v-model="form.PayoutBankName" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="legal identity" rules="required"
                                    tag="div">
                  <label for="legalIdentity">Legal Entity Identifier (LEI)</label>
                  <input id="legalIdentity" v-model="form.PayoutBankLegalEntityIdentifier" class="form-control"
                         type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="business address" rules="required"
                                    tag="div">
                  <label for="businessAddress">Business Address</label>
                  <input id="businessAddress" v-model="form.PayoutBankAddress" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="business postal code" rules="required"
                                    tag="div">
                  <label for="businessPostalCode">Business Address</label>
                  <input id="businessPostalCode" v-model="form.PayoutBankPostalCode" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="business city" rules="required"
                                    tag="div">
                  <label for="businessCity">Business City</label>
                  <input id="businessCity" v-model="form.PayoutBankCity" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="business state" rules="required"
                                    tag="div">
                  <label for="businessState">Business State</label>
                  <input id="businessState" v-model="form.PayoutBankState" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="business country" rules="required"
                                    tag="div">
                  <label for="businessCountry">Business Country</label>
                  <select id="businessCountry" v-model="form.PayoutBankCountry" class="form-control">
                    <option v-for="(country, key) in countries" :key="key" :value="key">{{ country }}</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </template>

              <label class="title">Beneficiary Identity Information</label>

              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="identity type" rules="required" tag="div">
                <label for="identityType">Identity Type</label>
                <select id="identityType" v-model="form.PayoutIdentityType" class="form-control">
                  <option value="1">Identity Card</option>
                  <option value="2">Passport</option>
                  <option value="3">Driving License</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="identity card number" rules="required" tag="div">
                <label for="identityCardNr">Identity Card Number</label>
                <input id="identityCardNr" v-model="form.PayoutIdentityCardNr" class="form-control"
                       type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="identity country" rules="required" tag="div">
                <label for="identityCountry">Identity Card Issue Country</label>
                <select id="identityCountry" v-model="form.PayoutIdentityCountry" class="form-control">
                  <option v-for="(country, key) in countries" :key="key" :value="key">{{ country }}</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>

              <label class="title">Income Source</label>

              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="income source" rules="required" tag="div">
                <label for="incomeSource">Income Source</label>
                <select id="incomeSource" v-model="incomeSource.source" class="form-control">
                  <option value="">Select Income Source</option>
                  <option v-for="(source, index) in incomeSources" :key="index" :value="source">{{ source }}</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="incomeSource.source.toLowerCase() === 'other'" v-slot="{ errors }"
                                  class="form-group"
                                  name="detail" rules="required" tag="div">
                <label for="incomeDetail">Detail</label>
                <textarea id="incomeDetail" v-model="incomeSource.detail" class="form-control"></textarea>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>

              <div class="text-right mb-8">
                <button class="btn btn-default btn-flat mr-8" type="button" @click="isEdit = false">Cancel</button>
                <button :disabled="loader !== '' || isLoading" class="btn btn-primary btn-flat" type="submit">
                  Update
                  <span v-if="isLoading">
                    <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                  </span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div class="col-md-6">
        <div class="table-responsive">
          <table class="table table-condensed table-striped">
            <tbody>
            <tr>
              <th colspan="2">General Information</th>
            </tr>
            <tr>
              <td style="min-width: 220px;width: 220px;">Has Balance</td>
              <td v-html="formatBadge(hasBalance)"></td>
            </tr>
            <tr>
              <td>Submitted</td>
              <td v-html="formatBadge(user.Verified)"></td>
            </tr>
            <tr>
              <td>Verified</td>
              <td v-html="formatStatus(user.FinalVerification)"></td>
            </tr>
            <tr>
              <td>Disabled</td>
              <td v-html="formatBadge(user.Disabled, true)"></td>
            </tr>
            <tr>
              <td>Email Confirmed</td>
              <td v-html="formatBadge(user.ConfirmedEmail)"></td>
            </tr>
            <tr>
              <td>2FA Enabled</td>
              <td v-html="formatBadge(user.Google2FAEnabled)"></td>
            </tr>
            <tr>
              <td>Master Transaction Function</td>
              <td v-html="formatBadge(user.MasterTransactionFunction)"></td>
            </tr>
            <tr>
              <td>Customer Email Notifications</td>
              <td v-html="formatBadge(user.EmailNotificationsCustomer)"></td>
            </tr>
            <tr>
              <td>Registered On</td>
              <td>
                <span class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.RegisteredOn)"></i>
                </span>
                {{ user.RegisteredOn }}
              </td>
            </tr>
            <tr>
              <td>Last Updated On</td>
              <td>
                <span class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.LastUpdatedOn)"></i>
                </span>
                {{ user.LastUpdatedOn }}
              </td>
            </tr>
            <tr>
              <td>Last Login On</td>
              <td>
                <span class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.LastLoginOn)"></i>
                </span>
                {{ user.LastLoginOn }}
              </td>
            </tr>
            <tr>
              <td>Last Login Country</td>
              <td>
                <span class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.LastLoginCountry)"></i>
                </span>
                {{ user.LastLoginCountry }}
              </td>
            </tr>
            <tr>
              <td>Last Login IP</td>
              <td>
                <span v-if="user.LastLoginIP !== 'NA'" class="mr-8">
                  <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.LastLoginIP)"></i>
                </span>
                <a v-if="user.LastLoginIP !== 'NA'"
                   :href="'https://mxtoolbox.com/SuperTool.aspx?action=ptr:' + user.LastLoginIP + '&run=toolpage'"
                   target="_blank">{{ user.LastLoginIP }}</a>
                <span v-else>{{ user.LastLoginIP }}</span>
              </td>
            </tr>
            <tr>
              <th>Location Information</th>
              <th class="text-right">
                <a @click="launchUpdateLocationModal()">
                  <i class="fa fa-edit"></i>
                </a>
              </th>
            </tr>
            <tr>
              <td>Latitude</td>
              <td>{{ user.Latitude }}</td>
            </tr>
            <tr>
              <td>Longitude</td>
              <td>{{ user.Longitude }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="text-right mb-16">
          <button v-if="isAuthorized('admin-commander')"
                  class="btn btn-primary btn-flat btn-sm mr-8"
                  @click="launchAddKYCFilesModal()">Add Files
          </button>
          <button v-tooltip="{content: 'Obtain Images', trigger: 'click hover focus'}"
                  class="btn btn-flat btn-info btn-icon btn-sm" @click="loadImages()">
            <svg v-if="loader !== 'images'" class="w-24 h-24" fill="none"
                 stroke="currentColor"
                 viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"></path>
            </svg>
            <i v-if="loader === 'images'" class="fa fa-circle-o-notch fa-spin"></i>
          </button>
        </div>
        <div v-if="user.files" class="mb-16">
          <silent-box :gallery="generateGallery(user.files)"></silent-box>
        </div>
        <div class="text-right mb-8">
          <span class="mr-8">
            <i class="fa fa-clipboard pointer" @click="copyToClipboard(user.Logs)"></i>
          </span>
          <button class="btn btn-primary btn-sm btn-flat"
                  @click="launchUpdateLogsModal()">Update Logs
          </button>
        </div>
        <textarea v-model="user.Logs" aria-label="" class="form-control" readonly rows="12"
                  style="min-width: 100%"></textarea>
      </div>
    </div>
    <!-- Add/Update KYC Files -->
    <add-k-y-c-files-modal></add-k-y-c-files-modal>
    <!-- Update logs Modal -->
    <logs-modal></logs-modal>
    <!-- Location Modal -->
    <location-modal></location-modal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import AddKYCFilesModal from "@/components/modals/files/AddKYCFilesModal";
import { generatePayload, isAuthorized, logout, saveNotification } from "@/lib/utils";
import { mapGetters } from "vuex";
import { SAVE_ACCOUNT } from "@/store/keys";
import LogsModal from "@/components/merchant-detail/modals/LogsModal";
import LocationModal from "@/components/merchant-detail/modals/LocationModal";

export default {
  name: "UserDetail",
  components: {
    LogsModal,
    LocationModal,
    AddKYCFilesModal,
    DatePicker,
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    hasBalance: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: "",
      isLoading: false,
      isEditableBirthDate: false,
      formBirthDate: {
        BirthDate: "",
      },
      isEdit: false,
      form: {
        BusinessAccount: "",
        PayoutBankName: "",
        PayoutBankLegalEntityIdentifier: "",
        PayoutBankAddress: "",
        PayoutBankPostalCode: "",
        PayoutBankCity: "",
        PayoutBankState: "",
        PayoutBankCountry: "",
        PayoutFullName: "",
        PayoutBirthDate: "",
        PEP: "",
        PayoutFirstName: "",
        PayoutLastName: "",
        PayoutAddress: "",
        PayoutPostalCode: "",
        PayoutCity: "",
        PayoutState: "",
        PayoutCountry: "",
        PayoutIdentityCardNr: "",
        PayoutIdentityType: "",
        PayoutIdentityCountry: "",
        MasterTransactionFunction: "",
        EmailNotificationsCustomer: "",
      },
      incomeSources: [
        "Salary",
        "Savings",
        "Gifts",
        "Inherited",
        "Other",
      ],
      incomeSource: {
        source: "",
        detail: "",
      },
      incomeSourceAction: "INSERT",
    };
  },
  computed: {
    ...mapGetters([
      "countries",
    ]),
  },
  watch: {
    user(val) {
      if (val) {
        this.updateValues();
      }
    },
  },
  methods: {
    formatIdentityType(type) {
      let formattedType = "";
      switch (type) {
        case "1":
          formattedType = "Identity Card";
          break;
        case "2":
          formattedType = "Passport";
          break;
        case "3":
          formattedType = "Driving License";
          break;
      }
      return formattedType;
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(function () {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, function (err) {
        console.error("Async: Could not copy text: ", err);
      });
    },
    isAuthorized(permission) {
      return isAuthorized(permission);
    },
    updateValues() {
      this.formBirthDate.BirthDate = this.user.PayoutBirthDate;
    },
    generateGoogleAddress(user, type) {
      let url = "https://www.google.com/maps/place/";
      const urlParts = [];
      if (type === "business") {
        if (user.PayoutBankAddress) {
          urlParts.push(user.PayoutBankAddress);
        }
        if (user.PayoutBankCity) {
          urlParts.push(user.PayoutBankCity);
        }
        if (user.PayoutBankState) {
          urlParts.push(user.PayoutBankState);
        }
        if (user.PayoutBankCountry) {
          urlParts.push(user.PayoutBankCountry);
        }
      } else {
        if (user.PayoutAddress) {
          urlParts.push(user.PayoutAddress);
        }
        if (user.PayoutCity) {
          urlParts.push(user.PayoutCity);
        }
        if (user.PayoutState) {
          urlParts.push(user.PayoutState);
        }
        if (user.PayoutCountry) {
          urlParts.push(user.PayoutCountry);
        }
      }
      url += urlParts.join(",");
      return url;
    },
    launchEditBirthDateForm() {
      if (!this.user.FinalVerification) {
        this.isEditableBirthDate = true;
        this.formBirthDate.BirthDate = this.user.PayoutBirthDate;
      } else {
        this.$toast.fire("", "Verified record update not allowed", "warning");
        saveNotification("Verified record update not allowed");
      }
    },
    getIncomeSourceData() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/MarketAPI`, {
        params: {
          POSDATA: "",
          Call: "SHOW",
          KeyData: this.user.ID + "_kyc",
          MerchantID: this.user.ID,
          APIKey: this.user.APIKey,
        },
      }).then(response => {
        if (response.data === "nothing found") {
          this.incomeSourceAction = "INSERT";
          this.incomeSource = {
            source: "",
            detail: "",
          };
        } else {
          this.incomeSourceAction = "UPDATE";
          this.incomeSource = response.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    saveIncomeSource() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const fd = new FormData();
      fd.append("Call", this.incomeSourceAction);
      fd.append("KeyData", this.user.ID + "_kyc");
      fd.append("ValueData", JSON.stringify(this.incomeSource));
      fd.append("MerchantID", this.user.ID);
      fd.append("APIKey", this.user.APIKey);
      fd.append("output", "json");
      this.$http.post(`${apiBaseUrl}/MarketAPI?POSDATA`, fd, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        if (response.status === 200) {
          this.incomeSourceAction = "UPDATE";
        }
      }).catch(error => {
        console.log(error);
      });
    },
    editForm() {
      this.isEdit = true;
      this.form = {
        BusinessAccount: this.user.AccountType.toLowerCase() === "business" ? 1 : 0,
        PayoutBankName: this.user.PayoutBankName,
        PayoutBankLegalEntityIdentifier: this.user.PayoutBankLegalEntityIdentifier,
        PayoutBankAddress: this.user.PayoutBankAddress,
        PayoutBankPostalCode: this.user.PayoutBankPostalCode,
        PayoutBankCity: this.user.PayoutBankCity,
        PayoutBankState: this.user.PayoutBankState,
        PayoutBankCountry: this.user.PayoutBankCountry,
        PayoutFirstName: this.user.PayoutFirstName === null ? "" : this.user.PayoutFirstName,
        PayoutLastName: this.user.PayoutLastName === null ? "" : this.user.PayoutLastName,
        PayoutFullName: this.user.PayoutFullName === null ? "" : this.user.PayoutFullName,
        PayoutBirthDate: this.tz(new Date(this.user.PayoutBirthDate), "Atlantic/Azores").format("YYYY-MM-DD"),
        PEP: this.user.PEP ? 1 : 0,
        PayoutAddress: this.user.PayoutAddress,
        PayoutPostalCode: this.user.PayoutPostalCode,
        PayoutCity: this.user.PayoutCity,
        PayoutState: this.user.PayoutState !== null ? this.user.PayoutState : "",
        PayoutCountry: this.user.PayoutCountry,
        PayoutIdentityType: this.user.PayoutIdentityType === null ? "" : this.user.PayoutIdentityType,
        PayoutIdentityCardNr: this.user.PayoutIdentityCardNr === null ? "" : this.user.PayoutIdentityCardNr,
        PayoutIdentityCountry: this.user.PayoutIdentityCountry === null ? "" : this.user.PayoutIdentityCountry,
        MasterTransactionFunction: this.user.MasterTransactionFunction,
        EmailNotificationsCustomer: this.user.EmailNotificationsCustomer,
      };
    },
    onSubmit: function () {
      const form = JSON.parse(JSON.stringify(this.form));
      let payload = { ...this.user };
      payload.BusinessAccount = form.BusinessAccount;
      payload.PayoutBankName = form.PayoutBankName;
      payload.PayoutBankLegalEntityIdentifier = form.PayoutBankLegalEntityIdentifier;
      payload.PayoutBankAddress = form.PayoutBankAddress;
      payload.PayoutBankPostalCode = form.PayoutBankPostalCode;
      payload.PayoutBankCity = form.PayoutBankCity;
      payload.PayoutBankState = form.PayoutBankState;
      payload.PayoutBankCountry = form.PayoutBankCountry;
      payload.PayoutFirstName = form.PayoutFirstName;
      payload.PayoutLastName = form.PayoutLastName;
      payload.PayoutFullName = form.PayoutFullName;
      payload.PayoutBirthDate = form.PayoutBirthDate;
      payload.PEP = form.PEP;
      payload.PayoutAddress = form.PayoutAddress;
      payload.PayoutPostalCode = form.PayoutPostalCode;
      payload.PayoutCity = form.PayoutCity;
      payload.PayoutState = form.PayoutState;
      payload.PayoutCountry = form.PayoutCountry;
      payload.PayoutIdentityType = form.PayoutIdentityType;
      payload.PayoutIdentityCardNr = form.PayoutIdentityCardNr;
      payload.PayoutIdentityCountry = form.PayoutIdentityCountry;
      payload.MasterTransactionFunction = form.MasterTransactionFunction;
      payload.EmailNotificationsCustomer = form.EmailNotificationsCustomer;
      payload = generatePayload(payload);
      this.isLoading = true;
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.saveIncomeSource();
          this.$emit("refresh", this.user.ID);
          this.$toast.fire("", "KYC/AML settings updated successfully", "success");
          saveNotification("KYC/AML settings updated successfully");
          this.isEdit = false;
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
    updateBirthDate() {
      if (this.formBirthDate.BirthDate !== "") {
        this.loader = "updateBirthDate";
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        this.$http.get(`${apiBaseUrl}/v2REAPI`, {
          params: {
            Call: "UpdateBirthdate",
            MerchantID: this.user.ID,
            APIKey: this.user.APIKey,
            Date: this.formBirthDate.BirthDate,
            output: "json",
          },
        }).then(response => {
          this.loader = "";
          if (response.data.status_code === 200) {
            this.isEditableBirthDate = false;
            this.user.PayoutBirthDate = this.formBirthDate.BirthDate;
            this.$toast.fire("", response.data.message, "success");
          }
        }).catch(error => {
          this.loader = "";
          console.log(error);
        });
      }
    },
    getPayoutSchemaType(payoutSchema) {
      if (payoutSchema === "Keep with Cointopay") {
        return "";
      }
      const banks = ["Pay out EUR Bank", "Pay out USD Bank", "Pay out GBP Bank"];
      const payeers = ["Pay out Paypal Payeer", "Pay out Payeer"];
      if (banks.indexOf(payoutSchema) !== -1) {
        return "bank";
      }
      if (payeers.indexOf(payoutSchema) !== -1) {
        return "payeer";
      }
      if (payoutSchema === "Pay out to bank address book default") {
        return "address-book-bank";
      }
      if (payoutSchema === "Pay out to crypto address book default") {
        return "address-book-crypto";
      }
    },
    formatStatus(status) {
      const formattedStatus = status ? "Verified" : "Unverified";
      const lblClass = status ? "label-success" : "label-danger";
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    formatBadge(status, invertedClasses = false) {
      const formattedStatus = status ? "Yes" : "No";
      let lblClass = status ? "label-success" : "label-danger";
      if (invertedClasses) {
        lblClass = !status ? "label-success" : "label-danger";
      }
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    generateGallery(files) {
      const images = [];
      if (files.passport !== "") {
        images.push({
          src: files.passport,
          thumbnailWidth: "220px",
          thumbnailHeight: "160px",
        });
      }
      if (files.avatar !== "") {
        images.push({
          src: files.avatar,
          thumbnailWidth: "220px",
          thumbnailHeight: "160px",
        });
      }
      return images;
    },
    launchAddKYCFilesModal() {
      this.$bus.$emit("add-kyc-files-modal", {
        title: "Add/Update KYC Files",
        data: this.user,
        confirm: (files) => {
          this.$emit("updateFiles", files);
        },
      });
    },
    loadImages() {
      this.$emit("updateFiles");
    },
    launchUpdateLogsModal() {
      this.$bus.$emit("logs-modal", {
        title: "Update Logs",
        data: this.user,
        confirm: () => {
          this.$emit("refresh", this.user.ID);
        },
      });
    },
    launchUpdateLocationModal() {
      this.$bus.$emit("location-modal", {
        title: "Update Merchant Location",
        data: this.user,
        confirm: (location) => {
          if (Object.keys(location).length === 2) {
            this.user.Latitude = location.Latitude;
            this.user.Longitude = location.Longitude;
          }
        },
      });
    },
  },
  mounted() {
    if (this.user) {
      this.updateValues();
      this.getIncomeSourceData();
      if (this.$route.query.edit && this.$route.query.edit.toString() === "true") {
        this.editForm();
      }
    }
  },
};
</script>

<style scoped>

</style>
