<template>
  <div id="bsModalPlatformInterconnect" aria-labelledby="bsModalLabel" class="modal fade"
       data-backdrop="static" data-keyboard="false"
       @click="clickOverlay()"
       data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span
              aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="merchant id" rules="required" tag="div">
                <label>Merchant ID</label>
                <input v-model="form.MerchantID" aria-label="" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="api key" rules="required" tag="div">
                <label>API Key</label>
                <input v-model="form.APIKey" aria-label="" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="url" rules="required" tag="div">
                <label>Url</label>
                <input v-model="form.Url" aria-label="" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="port" rules="" tag="div">
                <label>Port</label>
                <input v-model="form.Port" aria-label="" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button class="btn btn-primary btn-flat" type="submit">Create Url</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PlatformInterconnectModal",
  data() {
    return {
      title: "",
      data: null,
      form: {
        MerchantID: "",
        APIKey: "",
        AltCoinID: "",
        Url: "",
        Port: "",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    confirm: function (url) {
      this.confirmHook(url);
      this.close();
    },
    onSubmit: function () {
      const port = this.form.Port ? ":" + this.form.Port : "";
      let u = this.form.Url.split("//");
      if (u.length === 2) {
        u = u[1];
      } else {
        u = u[0];
      }
      const url = `https://${u}${port}?MerchantID=${this.form.MerchantID}&AltCoinID=${this.form.AltCoinID}&Call=Interconnect&APIKey=${this.form.APIKey}&output=raw`;
      this.confirm(url);
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalPlatformInterconnect").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("platform-interconnect-modal", (args) => {
      this.title = args.title;
      this.data = args.data;
      if (this.data) {
        this.form.AltCoinID = this.data.AltCoinID;
      }
      this.confirmHook = args.confirm;
      $("#bsModalPlatformInterconnect").modal("show");
    });
  },
};
</script>

<style scoped></style>
