<template>
  <div id="bsModalInputCurrency" aria-labelledby="bsModalLabel" class="modal fade"
       data-backdrop="static" data-keyboard="false"
       data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span
              aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group"
                                  name="input currency" rules="required" tag="div">
                <label for="currency">Default Input Currency</label>
                <v-select id="currency" v-model="form.PayoutCurrencyShortName" :options="inputCurrenciesList"
                          :reduce="coin => coin.ShortName" label="Name" taggable></v-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  Update
                  <span v-if="isLoading">
                    <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                  </span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { generatePayload, logout, saveNotification } from "@/lib/utils";
import { SAVE_ACCOUNT } from "@/store/keys";

export default {
  name: "InputCurrencyModal",
  data() {
    return {
      isLoading: false,
      title: "",
      user: null,
      form: {
        PreferredCoinID: "",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "onlineAltCoins",
    ]),
    inputCurrenciesList() {
      return this.onlineAltCoins.filter(coin => coin.isInputCurrency);
    },
  },
  methods: {
    confirm: function () {
      this.confirmHook();
      this.close();
    },
    onSubmit: function () {
      this.isLoading = true;
      const form = JSON.parse(JSON.stringify(this.form));
      let payload = { ...this.user };
      payload.PayoutCurrencyShortName = form.PayoutCurrencyShortName;
      payload = generatePayload(payload);
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$toast.fire("", "Default input currency updated successfully", "success");
          saveNotification("Default input currency updated successfully");
          // Close modal
          this.confirm();
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalInputCurrency").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("input-currency-modal", (args) => {
      this.title = args.title;
      this.user = args.data || null;
      this.confirmHook = args.confirm;
      $("#bsModalInputCurrency").modal("show");
      this.form = {
        PayoutCurrencyShortName: this.user.PayoutCurrencyShortName,
      };
    });
  },
};
</script>

<style scoped></style>
