var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"bsModalSwapModal","aria-labelledby":"bsModalLabel","data-backdrop":"static","data-keyboard":"false","data-toggle":"modal","role":"dialog","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"aria-label":"Close","type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('h4',{staticClass:"modal-title",attrs:{"id":"bsModalLabel"}},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"modal-body"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"altCoinID","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"swapTargetAltCoinID"}},[_vm._v("Target AltCoinID")]),_c('v-select',{attrs:{"id":"swapTargetAltCoinID","options":_vm.onlineAltCoins,"reduce":function (coin) { return coin.ID; },"label":"Name"},model:{value:(_vm.form.TargetAltCoinID),callback:function ($$v) {_vm.$set(_vm.form, "TargetAltCoinID", $$v)},expression:"form.TargetAltCoinID"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"amount","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex align-items-center justify-between"},[_c('label',{attrs:{"for":"crypto-deposit-amount"}},[_vm._v("Amount - "+_vm._s(_vm.sourceCoin.LongName))]),(_vm.form.TargetAltCoinID)?_c('a',{on:{"click":function($event){return _vm.updateAmount()}}},[_vm._v("All Amount")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Amount),expression:"form.Amount"}],staticClass:"form-control",attrs:{"id":"crypto-deposit-amount","type":"text"},domProps:{"value":(_vm.form.Amount)},on:{"keyup":function($event){return _vm.calculate('amount')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Amount", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.form.TargetAltCoinID)?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"targetAmount","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"crypto-amount"}},[_vm._v("Amount - "+_vm._s(_vm.targetCoin.LongName))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.targetAmount),expression:"targetAmount"}],staticClass:"form-control",attrs:{"id":"crypto-amount","type":"text"},domProps:{"value":(_vm.targetAmount)},on:{"keyup":function($event){return _vm.calculate('targetAmount')},"input":function($event){if($event.target.composing){ return; }_vm.targetAmount=$event.target.value}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-flat",attrs:{"disabled":_vm.isLoading,"type":"submit"}},[(!_vm.isLoading)?_c('span',[_vm._v("Swap")]):_vm._e(),(_vm.isLoading)?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin"})]):_vm._e()])])],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }