<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <div class="flex align-items-center justify-between">
            <select aria-label="" class="form-control" v-model="form.altCoinID">
              <option value="">Select AltCoin</option>
              <option v-for="(coin, index) in selectedAltCoins" :value="coin.ID" :key="index">
                {{ coin.Name + ' - ' + coin.LongName }}
              </option>
            </select>
            <button class="btn btn-primary btn-flat ml-8"
                    :disabled="form.altCoinID === ''" @click="addCoin()">Add
            </button>
          </div>
        </div>
        <div v-for="(record, index) in records" :key="index">
          <div class="coin-box" @click="editCoin(record)"
               :class="{ 'active': selectedCoin && selectedCoin.AltCoinID === record.AltCoinID }">
            {{ 'AltCoinID: ' + record.AltCoinID + ' - ' + record.CoinName + ' (' + record.LongName + ')' }}
            <i class="fa fa-check-circle text-green ml-8"
               v-if="record.AutonomyAccount"></i>
          </div>
        </div>
        <hr v-if="records.length > 0"/>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div v-for="(record, index) in form.autonomy" :key="index">
              <div class="flex align-items-center justify-between mb-16">
                <label>{{ generateCoinLabel(record.AltCoinID) }}</label>
                <button type="button" class="btn btn-danger btn-flat btn-sm"
                        v-if="isEditable(record.AltCoinID)" @click="removeCoin(index)">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="autonomy user"
                                  :rules="{ required: true, regex: /^[0-9a-zA-Z-. ]+$/ }" tag="div">
                <div class="row">
                  <label class="col-md-4" for="autonomy-user">
                    {{ record.XChangeAutonomy ? 'API Key' : 'Autonomy User' }}
                  </label>
                  <div class="col-md-8">
                    <input id="autonomy-user" v-model="record.AutonomyUser" class="form-control" type="text"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="autonomy password"
                                  :rules="{ required: true, regex: /^[0-9a-zA-Z-!@#$^&*. ]+$/ }" tag="div">
                <div class="row">
                  <label class="col-md-4" for="autonomy-password">
                    {{ record.XChangeAutonomy ? 'API Secret' : 'Autonomy Password' }}
                  </label>
                  <div class="col-md-8">
                    <input id="autonomy-password" v-model="record.AutonomyPassword" class="form-control" type="text"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="autonomy server"
                                  :rules="{ required: true, regex: /^[-:/0-9a-zA-Z.?&_= ]+$/ }" tag="div">
                <div class="row">
                  <label class="col-md-4" for="autonomy-server">Autonomy Server</label>
                  <div class="col-md-8">
                    <input id="autonomy-server" v-model="record.AutonomyServer" class="form-control" type="text"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <div class="form-group mb-0">
                <div class="row">
                  <label class="col-md-4">XChange Autonomy</label>
                  <div class="col-md-8">
                    <div class="flex align-items-center">
                      <div class="checkbox mr-8">
                        <label class="flex align-items-center no-wrap">
                          <input type="checkbox" v-model="record.XChangeAutonomy"/>XChange Autonomy
                        </label>
                      </div>
                      <select class="form-control" v-if="record.XChangeAutonomy" aria-label=""
                              @click="updateServer(index)" v-model="record.XChange">
                        <option value="">Select XChange</option>
                        <option v-for="(e, i) in  filteredExchangeList"
                                :value="e.Name" :key="i">{{ e.Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <label class="col-md-4">Platform Interconnect</label>
                  <div class="col-md-8">
                    <div class="checkbox mr-8">
                      <label class="flex align-items-center no-wrap">
                        <input type="checkbox" @click="launchPlatformInterconnect(index)"
                          v-model="record.PlatformInterconnect" :checked="record.PlatformInterconnect">Platform Interconnect
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right" v-if="form.autonomy.length > 0">
              <button type="button" class="btn btn-flat btn-primary mr-16" :disabled="isLoading"
                      @click="check()">Check
              </button>
              <button class="btn btn-flat btn-primary" :disabled="isLoading">Save</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <platform-interconnect-modal></platform-interconnect-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { generatePayload, logout, saveNotification } from "@/lib/utils";
import { SAVE_ACCOUNT } from "@/store/keys";
import PlatformInterconnectModal from "../modals/PlatformInterconnectModal";

export default {
  name: "Autonomy",
  components: { PlatformInterconnectModal },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectedAltCoins: [],
      selectedCoin: null,
      records: [],
      isLoading: false,
      form: {
        altCoinID: "",
        autonomy: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "onlineAltCoins",
      "jwtToken",
      "exchangeList",
    ]),
    filteredExchangeList() {
      return this.exchangeList.filter(e => e.Online);
    },
  },
  methods: {
    generateCoinLabel(id) {
      const coin = this._.find(this.selectedAltCoins, { ID: id });
      if (coin) {
        return coin.Name + " (" + coin.LongName + ")";
      }
      return "";
    },
    getSelectedAltCoins() {
      if (Object.keys(this.user).length > 0) {
        this.selectedAltCoins = [];
        const selectedAltCoins = this.user.SelectedAltCoins.split(",");
        this.onlineAltCoins.forEach(coin => {
          if (selectedAltCoins.indexOf(coin.ID.toString()) !== -1 && coin.AvailableForAutonomy === 1) {
            this.selectedAltCoins.push(coin);
          }
        });
      }
      return this._.orderBy(this.selectedAltCoins, [coin => coin.Name.toLowerCase()], "asc");
    },
    getMerchantAutonomy() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=merchant_autonomy`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          merchantID: this.user.ID,
        },
      }).then((response) => {
        if (response.status === 200) {
          this.records = response.data.data;
        }
      }).catch(error => {
        saveNotification(error.data.message);
      });
    },
    addCoin() {
      const index = this._.findIndex(this.form.autonomy, { AltCoinID: this.form.altCoinID });
      const eIndex = this._.findIndex(this.records, { AltCoinID: this.form.altCoinID });
      if (index === -1 && eIndex === -1) {
        this.form.autonomy.push({
          AltCoinID: this.form.altCoinID,
          AutonomyUser: "",
          AutonomyPassword: "",
          AutonomyServer: "",
          XChangeAutonomy: false,
          XChange: "",
          PlatformInterconnect: false,
        });
      }
    },
    editCoin(coin) {
      const index = this._.findIndex(this.form.autonomy, { AltCoinID: coin.AltCoinID });
      if (index === -1) {
        this.selectedCoin = coin;
        this.form.autonomy = [];
        this.form.autonomy.push({
          AltCoinID: coin.AltCoinID,
          AutonomyUser: coin.AutonomyUser,
          AutonomyPassword: "",
          AutonomyServer: coin.AutonomyServer,
          XChangeAutonomy: false,
          XChange: "",
          PlatformInterconnect: false,
        });
      }
    },
    removeCoin(index) {
      this.form.autonomy.splice(index, 1);
    },
    isEditable(altCoinID) {
      if (this.records.length > 0) {
        return this._.findIndex(this.records, { AltCoinID: altCoinID }) !== -1;
      }
      return false;
    },
    updateServer(index) {
      this.form.autonomy[index].AutonomyServer = this.form.autonomy[index].XChange;
    },
    launchPlatformInterconnect(index) {
      let autonomy = this.form.autonomy[index];
      if (!autonomy.PlatformInterconnect) {
        this.$bus.$emit("platform-interconnect-modal", {
          title: "Platform Interconnect",
          data: autonomy,
          confirm: (url) => {
            autonomy = Object.assign({}, autonomy, {
              AutonomyServer: url,
              PlatformInterconnect: true,
            });
            this.form.autonomy.splice(index, 1, autonomy);
          },
        });
      } else {
        autonomy = Object.assign({}, autonomy, {
          AutonomyServer: "",
          PlatformInterconnect: false,
        });
        this.form.autonomy.splice(index, 1, autonomy);
      }
    },
    showLoading() {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
    },
    stopLoading() {
      this.$swal.close();
    },
    check() {
      if (this.selectedCoin) {
        this.showLoading();
        const url = process.env.VUE_APP_API_URL;
        this.$http.get(`${url}/GetAccountAddress`, {
          params: {
            MerchantID: this.user.ID,
            AltCoinID: this.selectedCoin.AltCoinID,
            APIKey: this.user.APIKey,
          },
        }).then(response => {
          this.stopLoading();
          this.$toast.fire("", "Connection successful!", "success");
          this.getMerchantAutonomy();
        }).catch(error => {
          this.stopLoading();
          console.log(error);
        });
      }
    },
    onSubmit() {
      let payload = { ...this.user };
      payload = generatePayload(payload);
      this.form.autonomy.forEach(record => {
        payload[record.AltCoinID + "autonomyuser"] = record.AutonomyUser;
        payload[record.AltCoinID + "autonomypass"] = record.AutonomyPassword;
        payload[record.AltCoinID + "autonomyserver"] = record.AutonomyServer;
      });
      this.isLoading = true;
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$emit("refresh", this.user.ID);
          this.$toast.fire("", "Autonomy information updated successfully", "success");
          saveNotification("Autonomy information updated successfully");
          this.getMerchantAutonomy();
          this.selectedCoin = null;
          this.form.autonomy = [];
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
  },
  mounted() {
    this.getSelectedAltCoins();
    this.getMerchantAutonomy();
  },
};
</script>

<style scoped lang="scss">
.coin-box {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  padding: 1rem;
  display: inline-block;
  width: 300px;
  text-align: center;
  cursor: pointer;
  margin: 2px 8px 2px 0;

  &.active {
    background-color: rgb(54, 127, 169);
    color: #ffffff;

    .fa {
      color: #ffffff !important;
    }
  }
}
</style>
