<template>
  <div>
    <div class="mb-16">
      <label class="mr-8">
        <input :checked="isChecked(1)" name="fee" type="radio" value="1" @click="payFee(1)">
        <span class="ml-8">Merchant</span>
      </label>&emsp;
      <label class="mr-8">
        <input :checked="isChecked(2)" name="fee" type="radio" value="2" @click="payFee(2)">
        <span class="ml-8">Customer</span>
      </label>&emsp;
      <label>
        <input :checked="isChecked(3)" name="fee" type="radio" value="3" @click="payFee(3)">
        <span class="ml-8">Shared</span>
      </label>
    </div>
    <div class="flex">
      <p>A fee of 1.0102 percent is added to each incoming transaction.</p>
      <router-link :to="{name: 'merchant-fees-custom', query:{ merchant_id: this.user.ID } }" class="btn btn-primary btn-sm btn-flat ml-8">Merchant Fee Custom</router-link>
    </div>
    <p class="mb-0">Lifetime member status:&nbsp;<span v-html="getStatus"></span></p>
  </div>
</template>

<script>
import { saveNotification } from "../../lib/utils";

export default {
  name: "Billing",
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      status: 0,
    };
  },
  computed: {
    getStatus() {
      const statusClass = (this.status === -1) ? "label-danger" : "label-success";
      const label = (this.status === -1) ? "false" : "true";
      return `<label class="label ${statusClass}">${label}</label>`;
    },
  },
  watch: {
    user(val) {
      if (val) {
        this.updateValues();
      }
    },
  },
  methods: {
    updateValues() {
      if (this.user) {
        this.status = this.user.LifetimeMember;
      }
    },
    payFee(type) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Call: "FeesPaid",
        Type: type,
        MerchantID: this.user.ID,
        APIKey: this.user.APIKey,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        if (response.data.status_code === 200) {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.$emit("refresh", this.user.ID);
        }
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    isChecked(value) {
      return Number(this.user.FeesPaidBy) === value;
    },
  },
  mounted() {
    this.updateValues();
  },
};
</script>

<style scoped>

</style>
