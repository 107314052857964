<template>
  <div id="bsModalVTokenCheque" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <va-tabs :padding="'padding: 0'" @selectedTab="selectedTab">
            <!-- Create VToken -->
            <div id="create-vToken" slot="content" :data-title="'Create VToken'" class="active">
              <div v-if="successMessageCreate" class="form-group text-center">
                <img :src="'https://chart.googleapis.com/chart?chs=300&cht=qr&chl=' + successMessageCreate" alt="" />
                <br />
                <input ref="copy" :value="successMessageCreate" type="hidden">
                <span style="word-break: break-all;">{{ successMessageCreate }}</span>&emsp;
                <button class="btn btn-outline-success btn-sm d-inline-block" type="button"
                  @click="copyToClipboard(successMessageCreate)">
                  <img alt="" class="pointer w-20" src="@/assets/images/icon_clipboard.svg" />
                </button>
              </div>
              <ValidationObserver ref="observerCreate" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(createToken)">
                  <ValidationProvider v-slot="{ errors }" class="form-group" name="amount" rules="required" tag="div">
                    <label for="vtoken-amount" class="flex align-items-center justify-between mt-8">
                      <span>Amount</span>
                      <a class="pointer" v-if="record" @click="form.amount = record.TotalBalance">
                        {{ record.TotalBalance + ' ' + record.LongName }}</a>
                    </label>
                    <input id="vtoken-amount" v-model="form.amount" class="form-control" type="text"
                      @keyup="trimValue(form, 'amount')" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div class="text-right">
                    <button :disabled="loader !== '' || balance === 0" class="btn btn-primary btn-flat mb-8"
                      type="submit">
                      Create
                      <span v-if="loader === 'create'">
                        <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                      </span>
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <!-- Claim VToken -->
            <div id="claim-vToken" slot="content" :data-title="'Claim VToken'">
              <ValidationObserver ref="observerClaim" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(claimToken)">
                  <ValidationProvider v-slot="{ errors }" class="form-group" name="amount" rules="required" tag="div">
                    <div class="flex align-items-center justify-between mt-8 mb-8">
                      <label for="token">VToken</label>
                      <img alt="" class="w-24 pointer" src="@/assets/images/qr_image.png"
                        @click="isScanner = !isScanner" />
                    </div>
                    <input id="token" v-model="formClaim.token" class="form-control" type="text"
                      @keyup="trimValue(formClaim, 'token')" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div class="row mb-8" v-if="isScanner">
                    <div class="col-md-offset-3 col-md-6">
                      <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
                    </div>
                  </div>
                  <div class="text-right">
                    <button :disabled="loader !== ''" class="btn btn-primary btn-flat mb-8" type="submit">
                      Claim
                      <span v-if="loader === 'claim'">
                        <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                      </span>
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </va-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VaTabs from "@/components/VATabs";
import { QrcodeStream } from "vue-qrcode-reader";
import { mapGetters } from "vuex";
import { saveNotification } from "@/lib/utils";

export default {
  name: "VTokenChequeModal",
  components: {
    VaTabs,
    QrcodeStream,
  },
  data() {
    return {
      title: "",
      user: null,
      record: null,
      form: {
        amount: "",
      },
      formClaim: {
        token: "",
      },
      successMessageCreate: "",
      loader: "",
      isScanner: false,
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
    ]),
    balance() {
      if (this.record) {
        return Number(this.record.TotalBalance);
      }
      return 0;
    },
  },
  methods: {
    trimValue(form, key) {
      form[key] = form[key] ? form[key].trim() : "";
    },
    resetForm() {
      this.form.amount = "";
      this.$nextTick(() => this.$refs.observerCreate.reset());
    },
    resetClaimForm() {
      this.formClaim.token = "";
      this.$nextTick(() => this.$refs.observerClaim.reset());
    },
    createToken() {
      this.loader = "create";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Call: "VTOKEN",
        MerchantID: this.user.ID,
        APIKey: this.user.APIKey,
        AltCoinID: this.record.AltCoinID,
        Amount: this.form.amount,
        Direction: 1,
        output: "json",
        V2: "",
      };
      this.successMessageCreate = "";
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        this.loader = "";
        if (response.status === 200) {
          if (typeof response.data === "string") {
            if (response.data.toLowerCase().startsWith("payout") ||
              response.data.toLowerCase().startsWith("amount") ||
              response.data.toLowerCase() === "not enough balance for vtoken creation") {
              this.$toast.fire("", response.data.replace(/^\w/, c => c.toUpperCase()), "error");
              saveNotification(response.data.replace(/^\w/, c => c.toUpperCase()));
            } else {
              this.successMessageCreate = response.data;
              this.resetForm();
              this.confirmHook();
              this.getWalletsBalance();
            }
          }
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    claimToken() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Call: "VTOKEN",
        MerchantID: this.user.ID,
        APIKey: this.user.APIKey,
        AltCoinID: this.record.AltCoinID,
        Token: this.formClaim.token.trim(),
        Amount: 1,
        Direction: 0,
        output: "json",
        V2: "",
      };
      this.loader = "claim";
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        if (response.status === 200) {
          this.loader = "";
          if (typeof response.data === "string") {
            if (response.data.toLowerCase() === "ok") {
              this.$toast.fire("", "vToken claimed successfully", "success");
              saveNotification("vToken claimed successfully");
              this.resetClaimForm();
              this.confirmHook();
              this.getWalletsBalance();
            } else {
              this.$toast.fire("", response.data.replace(/^\w/, c => c.toUpperCase()), "error");
              saveNotification(response.data.replace(/^\w/, c => c.toUpperCase()));
            }
          }
        }
      }).catch(error => {
        this.loader = "";
        console.log(error.response);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalVTokenCheque").modal("hide");
      this.resetClaimForm();
      this.resetForm();
      $("[href=\"#create-vToken\"]").tab("show");
    },
    getWalletsBalance() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=BalanceOverview`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id: this.user.ID,
        },
      }).then(response => {
        if (response.status === 200) {
          const balanceList = response.data.data;
          this.record = this._.find(balanceList, { AltCoinID: this.record.AltCoinID });
        }
      }).catch(error => {
        console.log(error);
      });
    },
    selectedTab(id) {
      $("[href=\"#" + id + "\"]").tab("show");
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        let errorMsg = "";
        if (error.name === "NotAllowedError") {
          errorMsg = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          errorMsg = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          errorMsg = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          errorMsg = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          errorMsg = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          errorMsg = "ERROR: Stream API is not supported in this browser";
        }
        this.$toast.fire("", errorMsg, "error");
      }
    },
    onDecode(content) {
      if (content) {
        this.formClaim.token = content;
        this.isScanner = false;
      }
    },
  },
  mounted() {
    this.$bus.$on("vtoken-cheque-modal", (args) => {
      this.title = args.title;
      this.user = args.user || null;
      this.record = args.record || null;
      this.successMessageCreate = "";
      this.confirmHook = args.confirm;
      this.selectedTab("claim-vToken");
      $("#bsModalVTokenCheque").modal("show");
    });
  },
};
</script>

<style scoped></style>
