<template>
  <div>
    <va-loader v-if="balanceList.length === 0"></va-loader>
    <div v-if="balanceList.length > 0" class="row">
      <div class="col-sm-12 text-right">
        <span v-if="isLoading" class="text-danger mr-8 text-bold">Syncing ...</span>
        <label>
          <input v-model="showWithBalance" type="checkbox" />
          Show all with balance
        </label>
      </div>
      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table table-striped table-condensed mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Long Name</th>
                <th>Short Name</th>
                <th>Online</th>
                <th>Balance</th>
                <th>Balance({{ balanceList[0].Fiat }})</th>
                <th>VToken Balance</th>
                <th>Sell Balance</th>
                <th>Buy Balance</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(balance, index) in filteredBalanceList" :key="index">
                <td>
                  <router-link tag="a" :to="{ name: 'coin-health-detail', params: { id: balance.AltCoinID } }">
                    {{ balance.Name }}
                  </router-link>
                </td>
                <td>{{ balance.LongName }}</td>
                <td>{{ balance.ShortName }}</td>
                <td>
                  <span v-html="formatStatus(balance.Online)"></span>
                </td>
                <td>
                  <span class="flex" v-if="balance.TotalBalance !== ''">
                    {{ toFixed(balance.TotalBalance) }}
                    <span class="ml-8" v-if="balance.TotalBalance">
                      <i class="fa fa-copy pointer"
                        @click="toConverter(toFixed(balance.TotalBalance), balance.AltCoinID)"></i>
                    </span>
                  </span>
                </td>
                <td>{{ toFixed(balance.FiatValue) }}</td>
                <td>{{ toFixed(balance.VTokenBalance) }}</td>
                <td>{{ toFixed(balance.SellAmount) }}</td>
                <td>{{ toFixed(balance.BuyAmount) }}</td>
                <td style="min-width: 770px">
                  <template v-if="balance.ID !== 0">
                    <button :disabled="balance.TotalBalance <= 0" class="btn btn-primary btn-sm btn-flat"
                      @click="openCryptoWithdrawModal(balance)">Crypto Withdraw
                    </button>
                    <button class="btn btn-primary btn-sm btn-flat ml-8" @click="openCryptoDepositModal(balance)">Crypto
                      Deposit
                    </button>
                    <button class="btn btn-primary btn-sm btn-flat ml-8" style="width: 100px;"
                      @click="openBuyModal(balance)">Buy {{ balance.LongName }}
                    </button>
                    <button :disabled="balance.TotalBalance <= 0" class="btn btn-primary btn-sm btn-flat ml-8"
                      @click="openSwapModal(balance)">Swap
                    </button>
                    <button :disabled="isLTRDisabled(balance)" class="btn btn-primary btn-sm btn-flat ml-8" type="button"
                      @click="openCryptoAddressModal(balance)">
                      Crypto Address
                    </button>
                    <button class="btn btn-primary btn-sm btn-flat ml-8" type="button"
                      @click="openVTokenChequeModal(balance)">
                      VToken Cheque
                    </button>
                    <button :disabled="balance.TotalBalance <= 0" class="btn btn-primary btn-sm btn-flat ml-8"
                      type="button" @click="openBankPayoutModal(balance)">
                      Bank Payout
                    </button>
                    <button v-if="balance.TotalBalance > 0" class="btn btn-danger btn-sm btn-flat ml-8" type="button"
                      @click="thrashIt(balance)">
                      Thrash It
                    </button>
                  </template>
                </td>
              </tr>
              <tr v-if="filteredBalanceList.length">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ balanceList[0].Fiat + ' Total' }}</td>
                <td>{{ toFixed(getTotal(balanceList)) }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-if="filteredBalanceList.length === 0" style="height: 100px">
                <td class="text-center" colspan="10">
                  <button class="btn btn-primary btn-sm" @click="showWithBalance = false">
                    Click here to see the coin overview
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Crypto Withdraw Modal -->
    <crypto-withdraw-modal></crypto-withdraw-modal>
    <!-- Crypto Deposit Modal -->
    <crypto-deposit-modal @updateTransactions="updateTransactions"></crypto-deposit-modal>
    <!-- Buy Modal -->
    <buy-modal></buy-modal>
    <!-- Swap Modal -->
    <swap-modal></swap-modal>
    <!-- Crypto Address Modal -->
    <crypto-address-modal></crypto-address-modal>
    <!-- VToken Cheque -->
    <v-token-cheque-modal></v-token-cheque-modal>
    <!-- Bank Payout -->
    <bank-payout-modal></bank-payout-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CryptoDepositModal from "@/components/modals/merchant/CryptoDepositModal";
import CryptoWithdrawModal from "@/components/modals/merchant/CryptoWithdrawModal";
import SwapModal from "@/components/modals/merchant/SwapModal";
import CryptoAddressModal from "@/components/modals/merchant/CryptoAddressModal";
import { saveNotification, toFixed } from "@/lib/utils";
import VTokenChequeModal from "@/components/modals/merchant/VTokenChequeModal";
import VaLoader from "../partials/Loader";
import BankPayoutModal from "../modals/merchant/BankPayoutModal";
import BuyModal from "../modals/merchant/BuyModal";
import { SAVE_CONVERTER } from "../../store/keys";

export default {
  name: "BalanceDetail",
  components: {
    BuyModal,
    BankPayoutModal,
    VaLoader,
    VTokenChequeModal,
    CryptoAddressModal,
    CryptoDepositModal,
    CryptoWithdrawModal,
    SwapModal,
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    balanceList: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showWithBalance: true,
    };
  },
  computed: {
    ...mapGetters([
      "onlineAltCoins",
      "converter",
    ]),
    filteredBalanceList() {
      return this.balanceList.filter((item) => {
        if (this.showWithBalance) {
          return item.TotalBalance > 0 || item.VTokenBalance ||
            item.SellAmount > 0 || item.BuyAmount > 0;
        }
        return true;
      });
    },
  },
  methods: {
    toConverter(amount, altCoinID) {
      const form = { ...this.converter };
      form.source = amount;
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    toFixed(val) {
      return toFixed(val);
    },
    isLTRDisabled(record) {
      const coin = this._.find(this.onlineAltCoins, { ID: record.AltCoinID });
      return !coin || !coin.LTR;
    },
    formatStatus(val) {
      const formattedStatus = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-danger";
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    getTotal(balanceList) {
      const output = this._(balanceList)
        .groupBy("Fiat")
        .map((objs, key) => ({
          Fiat: key,
          FiatValue: this._.sumBy(objs, "FiatValue"),
        })).value();
      let total = output[0].FiatValue;
      let index = this._.findIndex(balanceList, { ShortName: "VEU" });
      if (index !== -1) {
        total += balanceList[index].TotalBalance;
      }
      index = this._.findIndex(balanceList, { ShortName: "EUR" });
      if (index !== -1) {
        total += balanceList[index].TotalBalance;
      }
      return total;
    },
    openCryptoWithdrawModal(record) {
      this.$bus.$emit("crypto-withdraw-modal", {
        title: "Crypto Withdraw",
        data: {
          ID: record.AltCoinID,
          MerchantID: this.user.ID,
          APIKey: this.user.APIKey,
          ShopTitle: this.user.ShopTitle,
          Balance: record.TotalBalance,
          Tag: record.tag,
          AddressBookCrypto: this.user.AddressBookCrypto ? this.user.AddressBookCrypto : "NA",
        },
        confirm: () => {
          setTimeout(() => {
            this.$emit("refreshBalance");
          }, 5000);
        },
      });
    },
    openBuyModal(record) {
      this.$bus.$emit("buy-modal", {
        title: "Buy",
        data: {
          ID: record.AltCoinID,
          MerchantID: this.user.ID,
          APIKey: this.user.APIKey,
        },
      });
    },
    openCryptoDepositModal(record) {
      this.$bus.$emit("crypto-deposit-modal", {
        title: "Crypto Deposit",
        data: {
          ID: record.AltCoinID,
          MerchantID: this.user.ID,
          SecurityCode: this.user.SecurityCode,
        },
      });
    },
    openSwapModal(record) {
      this.$bus.$emit("swap-modal", {
        title: "Swap",
        data: {
          ID: record.AltCoinID,
          MerchantID: this.user.ID,
          APIKey: this.user.APIKey,
          Amount: record.TotalBalance,
          PreferredCoinID: this.user.PreferredCoinID,
          isAutoSwap: this.user.SWAPPreferredCoinID,
        },
        confirm: () => {
          this.$emit("refreshBalance");
        },
      });
    },
    openCryptoAddressModal(record) {
      this.$bus.$emit("crypto-address-modal", {
        title: "LTR",
        user: this.user,
        record: record,
        confirm: () => {
          // Update transactions
          this.$emit("updateTransactions");
        },
      });
    },
    openVTokenChequeModal(record) {
      this.$bus.$emit("vtoken-cheque-modal", {
        title: "VToken Cheque",
        user: this.user,
        record: record,
        confirm: () => {
          // Update transactions
          this.$emit("updateTransactions");
          this.$emit("refreshBalance");
        },
      });
    },
    openBankPayoutModal(record) {
      this.$bus.$emit("bank-payout-modal", {
        title: "Bank Payout - " + record.LongName,
        user: this.user,
        record: record,
        confirm: () => {
          // Update transactions
          this.$emit("refreshBalance");
        },
      });
    },
    thrashIt(record) {
      this.$swal.fire({
        text: "Do you want to thrash the full amount?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.processThrashIt(record);
        } else {
          const balance = Number(record.TotalBalance).toFixed(8);
          this.$swal.fire({
            text: "Amount",
            html: `
              <div class="form-group text-left">
                <label for="amount">Amount</label>
                <input type="text" class="form-control" value="${balance}" id="amt" required/>
              </div>
              <div class="form-group text-left">
                <label for="message">Message</label>
                <textarea class="form-control" placeholder="Type your message here" id="message" rows="3"></textarea>
              </div>
            `,
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            focusCancel: true,
            preConfirm: () => {
              let amount = document.getElementById("amt").value;
              if (amount) {
                amount = Number(amount);
              }
              const message = document.getElementById("message").value;
              const pattern = new RegExp("^([A-Za-z0-9-:._!@#$^&*+?| /]+)$", "g");
              if (amount === "" || amount <= 0 || amount > balance) {
                this.$swal.showValidationMessage("Please provide valid amount");
              } else if (message !== "" && pattern.test(message) === false) {
                this.$swal.showValidationMessage("Message can only contain alphabets, numbers, space, underscore, hyphen and dot.");
              } else {
                return [
                  amount, message,
                ];
              }
            },
          }).then(({ value }) => {
            if (value && value.length === 2) {
              this.processThrashIt(record, value[0], value[1]);
            }
          });
        }
      });
    },
    processThrashIt(record, balance = null, message = "") {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "Thrashit",
          MerchantID: this.user.ID,
          Amount: balance ? Number(balance).toFixed(8) : Number(record.TotalBalance).toFixed(8),
          APIKey: this.user.APIKey,
          AltCoinID: record.AltCoinID,
          Message: message,
          V2: "",
          output: "json",
        },
      }).then(response => {
        if (response.status === 200) {
          this.$toast.fire("", "Balance trashed successfully", "success");
          saveNotification("Balance trashed successfully");
          this.$emit("refreshBalance");
        }
      }).catch(error => {
        console.log(error);
      });
    },
    updateTransactions() {
      this.$emit("updateTransactions");
    },
  },
};
</script>

<style scoped></style>
