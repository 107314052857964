<template>
  <div v-if="users">
    <div v-if="meta.total" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
    <div class="table-responsive">
      <table class="table table-condensed table-striped table-borderless">
        <thead>
          <tr>
            <th class="pointer" @click="sort('ID')">
              ID
              <span v-if="sortKey === 'ID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('OwnerNickName')">
              Owner Nick Name
              <span v-if="sortKey === 'OwnerNickName'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('ShopTitle')">
              Shop Title
              <span v-if="sortKey === 'ShopTitle'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Email')">
              Email
              <span v-if="sortKey === 'Email'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('FinalVerification')">
              Status
              <span v-if="sortKey === 'FinalVerification'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th>Roles</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="meta && meta.total">
            <tr v-for="(user, index) in users" :key="index">
              <td class="pl-16">
                <router-link :to="{ name: 'user-detail', params: { id: user.ID } }" tag="a">
                  {{ user.ID }}
                </router-link>
              </td>
              <td>{{ user.Name }}</td>
              <td>{{ user.ShopTitle }}</td>
              <td>{{ user.Email }}</td>
              <td v-html="formatStatus(user.FinalVerification, user.Disabled)"></td>
              <td v-html="formatRoles(user.Roles)"></td>
              <td>
                <button v-if="isAuthorized('assign-roles')" class="btn btn-primary btn-flat btn-sm"
                  @click="launchManageRoleModal(user)">Manage Role
                </button>
                <button v-else class="btn btn-default btn-flat btn-sm">
                  <i class="fa fa-lock"></i>
                </button>
                <router-link :to="{ name: 'kyc', params: { id: user.ID } }" class="btn btn-primary btn-flat btn-sm ml-4"
                  tag="a">KYC
                </router-link>
                <router-link :to="{ name: 'user-detail', params: { id: user.ID }, query: { edit: true } }"
                  class="btn btn-primary btn-flat btn-sm ml-4" tag="a">Edit
                </router-link>
                <router-link :to="{ name: 'user-detail', params: { id: user.ID } }"
                  class="btn btn-primary btn-flat btn-sm ml-4" tag="a">User
                </router-link>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7">No record found!</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="meta.total" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
    <assign-role-modal></assign-role-modal>
  </div>
</template>

<script>
import AssignRoleModal from "@/components/modals/AssignRoleModal.vue";
import Pagination from "@/components/Pagination.vue";
import { mapGetters } from "vuex";
import { isAuthorized } from "@/lib/utils";

export default {
  name: "Users",
  props: ["users", "meta"],
  components: {
    AssignRoleModal,
    Pagination,
  },
  data() {
    return {
      sortKey: "ID",
      order: "desc",
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "roles",
    ]),
  },
  methods: {
    isAuthorized(p) {
      return isAuthorized(p);
    },
    updatePage(page) {
    },
    formatStatus(status, disabled) {
      const formattedStatus = disabled === 1 ? "Disabled" : (status ? "Verified" : "Unverified");
      const lblClass = disabled === 1 ? "label-danger" : (status ? "label-success" : "label-warning");
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    formatRoles(roles) {
      let formattedRoles = "";
      if (roles) {
        roles = roles.split(",");
        roles.forEach(id => {
          const role = this._.find(this.roles, { ID: Number.parseInt(id, 10) });
          if (role) {
            formattedRoles += `<label class="label label-info mr-8">${role.Name}</label>`;
          }
        });
      }
      return formattedRoles;
    },
    launchManageRoleModal(user) {
      this.$bus.$emit("assign-role-modal", {
        title: "Manage User Role",
        data: user,
        confirm: () => {
          this.getUsers(this.meta.page, this.keyword);
        },
      });
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.$emit("sort", {
        order: this.order,
        sortKey: this.sortKey,
      });
    },
  },
};
</script>

<style scoped></style>
