<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider v-slot="{ errors }" class="form-group" name="payout schema" rules="required" tag="div">
              <div class="row">
                <label class="col-md-4" for="payoutSchema">Payout Schema</label>
                <div class="col-md-8">
                  <select id="payoutSchema" v-model="form.PayoutSchema" class="form-control">
                    <option v-for="(schema, index) in schemas" :key="index" :value="schema.title">
                      {{ schema.title }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <template v-if="isPayPal || isPayeer">
              <ValidationProvider v-slot="{ errors }" :name="isPayPal ? 'PayPal email' : 'payout account'"
                class="form-group" rules="required" tag="div">
                <div class="row">
                  <label class="col-md-4" for="payoutTo">
                    {{ isPayPal ? 'PayPal Email' : 'Payout Account' }}
                  </label>
                  <div class="col-md-8">
                    <input id="payoutTo" v-model="form.PayoutTo" class="form-control" type="text" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </template>
            <template v-if="isBank">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="account holder name" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="payoutName">Name on Bank Account</label>
                  <div class="col-md-8">
                    <input id="payoutName" v-model="form.PayoutName" class="form-control" type="text" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="iban/account number" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="payoutIBAN">IBAN/Bank Account</label>
                  <div class="col-md-8">
                    <input id="payoutIBAN" v-model="form.PayoutIBAN" class="form-control" type="text" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="bic/swift/routing code" rules="required"
                tag="div">
                <div class="row">
                  <label class="col-md-4" for="payoutBIC">BIC/SWIFT/Routing Code</label>
                  <div class="col-md-8">
                    <input id="payoutBIC" v-model="form.PayoutBIC" class="form-control" type="text" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </template>
            <div class="text-right">
              <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                Update
                <span v-if="isLoading">
                  <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                </span>
              </button>
            </div>
          </form>
        </ValidationObserver>
        <template>
          <div class="title">Bank Address Book</div>
          <div class="text-center">
            <button class="btn btn-primary btn-flat" @click="launchBankAddressBookModal()">Bank Address Book</button>
          </div>
        </template>
        <template>
          <div class="title">Crypto Address Book</div>
          <div class="text-center">
            <button class="btn btn-primary btn-flat" @click="launchCryptoAddressBookModal()">Crypto Address Book</button>
          </div>
        </template>
      </div>
    </div>
    <!-- Crypto Address Book -->
    <crypto-address-book-modal></crypto-address-book-modal>
  </div>
</template>

<script>
import { generatePayload, payoutSchemas, logout, saveNotification } from "@/lib/utils";
import { LIST_COUNTRIES, SAVE_ACCOUNT, TRANSFERWISE_CONFIG } from "@/store/keys";
import CryptoAddressBookModal from "@/components/merchant-detail/modals/CryptoAddressBookModal";

export default {
  name: "PayoutDetail",
  components: { CryptoAddressBookModal },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      schemas: payoutSchemas,
      form: {
        PayoutSchema: "",
        PayoutTo: "",
        PayoutName: "",
        PayoutIBAN: "",
        PayoutBIC: "",
      },
      isLoading: false,
    };
  },
  computed: {
    isPayPal() {
      return this.form.PayoutSchema === "Payout Paypal";
    },
    isPayeer() {
      return this.form.PayoutSchema === "Payout Payeer";
    },
    isBank() {
      return ["Pay out EUR Bank", "Pay out USD Bank", "Pay out GBP Bank"].indexOf(this.form.PayoutSchema) !== -1;
    },
    isAddressBookBank() {
      return this.form.PayoutSchema === "Pay out to bank address book default";
    },
    isAddressBookCrypto() {
      return this.form.PayoutSchema === "Pay out to crypto address book default";
    },
  },
  methods: {
    updateValues() {
      this.form.PayoutSchema = this.user.PayoutSchema;
      this.form.PayoutTo = this.user.PayoutTo;
      this.form.PayoutName = this.user.PayoutName;
      this.form.PayoutIBAN = this.user.PayoutIBAN;
      this.form.PayoutBIC = this.user.PayoutBIC;
    },
    onSubmit() {
      const form = JSON.parse(JSON.stringify(this.form));
      let payload = { ...this.user };
      payload.PayoutSchema = form.PayoutSchema;
      payload.PayoutTo = form.PayoutTo;
      payload.PayoutName = form.PayoutName;
      payload.PayoutIBAN = form.PayoutIBAN;
      payload.PayoutBIC = form.PayoutBIC;
      payload = generatePayload(payload);
      this.isLoading = true;
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$emit("refresh", this.user.ID);
          this.$toast.fire("", "Payout detail updated successfully", "success");
          saveNotification("Payout detail updated successfully");
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
    launchBankAddressBookModal() {
      this.$bus.$emit("bank-address-book-modal", {
        title: "Bank Address Book",
        data: this.user,
        confirm: () => {
          this.$emit("refresh", this.user.ID);
        },
      });
    },
    launchCryptoAddressBookModal() {
      this.$bus.$emit("crypto-address-book-modal", {
        title: "Crypto Address Book",
        data: this.user,
        confirm: () => {
          this.$emit("refresh", this.user.ID);
        },
      });
    },
  },
  mounted() {
    if (this.user) {
      this.updateValues();
      this.$store.dispatch(LIST_COUNTRIES);
      this.$store.dispatch(TRANSFERWISE_CONFIG);
    }
  },
};
</script>

<style scoped lang="scss">
.title {
  display: block;
  background: #efefef;
  padding: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
}
</style>
