<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-8">
          <div class="row">
            <div class="col-sm-2 col-md-2">
              <button class="btn btn-primary btn-sm btn-flat" type="button"
                      @click="exportToCSV()">Export to CSV
              </button>
            </div>
            <div class="col-sm-7 col-md-7 flex align-items-center justify-end">
              <label class="mr-8">From Date</label>
              <date-picker id="from-date" v-model="range.fromDate" :clearable="false"
                           :editable="false" format="YYYY-MM-DD"
                           placeholder="From date" type="date" value-type="format"
                           @change="searchActivities('from')"></date-picker>
              <label class="mr-8 ml-8">To Date</label>
              <date-picker id="to-date" v-model="range.toDate" :clearable="false"
                           :editable="false" format="YYYY-MM-DD"
                           placeholder="To date" type="date" value-type="format"
                           @change="searchActivities('to')"></date-picker>
            </div>
            <div class="col-sm-3 col-md-3">
              <input v-model="keyword" aria-label="" class="form-control" type="text"/>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-condensed table-striped mb-0">
            <thead>
            <tr>
              <th>ID</th>
              <th>Direction</th>
              <th>CreatedOn</th>
              <th>ConfirmedOn</th>
              <th>Confirmation Time</th>
              <th>Customer Reference Nr</th>
              <th>Original Amount</th>
              <th>Amount Received</th>
              <th>Fee Paid By Customer</th>
              <th>Input Currency</th>
              <th>Crypto Amount</th>
              <th>Crypto Name</th>
              <th>Coin Address</th>
            </tr>
            </thead>
            <tbody>
            <template v-if="paginatedActivities.length > 0">
              <tr v-for="(activity, index) in paginatedActivities" :key="index" class="no-wrap">
                <td>
                  <router-link :to="{ name: 'transaction-detail', params: { id: activity.ID } }" tag="a">
                    {{ activity.ID }}
                  </router-link>
                </td>
                <td>{{ activity.Incoming }}</td>
                <td>{{ formatDateTime(activity.CreatedOn) }}</td>
                <td>{{ formatDateTime(activity.ConfirmedOn) }}</td>
                <td>{{ activity.ConfirmationTime }}</td>
                <td style="word-break: break-all; width: 200px">
                  {{ activity.CustomerReferenceNr }}
                </td>
                <td>{{ activity.OriginalAmount }}</td>
                <td>{{ activity.AmountReceived }}</td>
                <td>{{ activity.FeePaidByCustomer }}</td>
                <td>{{ activity.InputCurrency }}</td>
                <td>{{ activity.CryptoCurrencyAmount }}</td>
                <td>{{ activity.Name }}</td>
                <td style="width: 150px;word-break: break-all;">
                  {{ activity.CoinAddressUsed }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="13">No record found!</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <div v-if="filteredActivities && filteredActivities.length > 0" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Pagination from "@/components/Pagination";

export default {
  name: "Activity",
  components: {
    Pagination,
    DatePicker,
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      activities: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      range: {
        fromDate: "",
        toDate: "",
      },
    };
  },
  computed: {
    filteredActivities() {
      return this.activities.filter(activity => {
        if (this.keyword) {
          const keyword = this.keyword.trim().toLowerCase();
          return (
            this._.includes(activity.ID.toLowerCase(), keyword) ||
            this._.includes(activity.CustomerReferenceNr.toLowerCase(), keyword) ||
            this._.includes(activity.InputCurrency?.toLowerCase(), keyword) ||
            this._.includes(activity.Incoming.toLowerCase(), keyword) ||
            this._.includes(activity.Name.toLowerCase(), keyword) ||
            this._.includes(activity.CreatedOn, keyword) ||
            this._.includes(activity.ConfirmedOn, keyword)
          );
        }
        return true;
      });
    },
    paginatedActivities() {
      if (this.filteredActivities.length > 0) {
        const offset = this.meta.page > 0 ? this.meta.page - 1 : 0;
        return this.filteredActivities.slice(offset * this.meta.limit, this.meta.page * this.meta.limit);
      }
      return [...this.filteredActivities];
    },
  },
  methods: {
    getActivities(range = null) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        WebShopReport: "",
        MerchantID: this.user.ID,
        APIKey: this.user.APIKey,
        AdminRange: 1,
      };
      if (range) {
        if (range.fromDate !== "") {
          Object.assign(payload, { fromdate: range.fromDate });
        }
        if (range.todate !== "") {
          Object.assign(payload, { todate: range.toDate });
        }
      }
      this.$http.get(`${apiBaseUrl}/V2Processor`, {
        params: payload,
      }).then(response => {
        if (response.status === 200) {
          if (response.data.data.length === 1 && Object.keys(response.data.data[0]).length === 0) {
            this.activities = [];
          } else {
            this.activities = response.data.data;
          }
          if (this.activities) {
            this.meta = Object.assign(this.meta, {
              total: this.activities.length,
              page: 1,
              offset: 0,
            });
          }
        }
      }).catch(error => {
        console.log(error);
      });
    },
    formatDateTime(dateTime) {
      if (dateTime === null || dateTime === "-" || dateTime === "") {
        return "-";
      }
      dateTime = dateTime.replace(/-/g, "/");
      return this.tz(new Date(dateTime)).format("YYYY-MM-DD H:mm:ss");
    },
    updatePage(page) {
      const offset = page > 0 ? page - 1 : 0;
      this.meta = Object.assign({}, this.meta, {
        page: page,
        offset: offset * this.meta.limit,
      });
    },
    searchActivities(type) {
      let error = false;
      if (type === "from") {
        const fromDate = this.range.fromDate;
        const toDate = this.range.toDate;
        // Check if from date is less or equal to today
        if (new Date(fromDate).getTime() > new Date().getTime()) {
          error = true;
          this.$toast.fire("", "From date cannot be greater than today", "error");
          this.range.fromDate = "";
        } else if (fromDate !== "" && toDate !== "") {
          if (new Date(fromDate).getTime() > new Date(toDate).getTime()) {
            this.range.fromDate = "";
            error = true;
            this.$toast.fire("", "From date should be less than to date", "error");
          }
        } else {
          if (this.range.toDate === "") {
            this.range.toDate = this.tz().format("YYYY-MM-DD");
          }
        }
      }
      if (!error && (this.range.fromDate !== "" || this.range.toDate !== "")) {
        this.getActivities(this.range);
      }
    },
    exportToCSV() {
      const data = [
        [
          "ID",
          "Direction",
          "CreatedOn",
          "ConfirmedOn",
          "ConfirmationTime",
          "CustomerReferenceNr",
          "OriginalAmount",
          "AmountReceived",
          "FeePaidByCustomer",
          "InputCurrency",
          "CryptoAmount",
          "CryptoName",
          "CoinAddressUsed",
        ],
      ];
      if (this.activities.length > 0) {
        this.activities.forEach(activity => {
          data.push([
            activity.ID,
            activity.Incoming,
            this.formatDateTime(activity.CreatedOn),
            this.formatDateTime(activity.ConfirmedOn),
            activity.ConfirmationTime,
            activity.CustomerReferenceNr,
            activity.OriginalAmount,
            activity.AmountReceived,
            activity.FeePaidByCustomer,
            activity.InputCurrency,
            activity.CryptoCurrencyAmount,
            activity.Name,
            activity.CoinAddressUsed,
          ]);
        });
      }

      const csvContent = data.map(e => e.join(";")).join("\n");
      const encodedUri = encodeURIComponent(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
      link.setAttribute("download", this.user.ID + "-activities.csv");
      document.body.appendChild(link);
      link.click();
    },
  },
  mounted() {
    if (this.user) {
      this.getActivities();
    }
  },
};
</script>

<style scoped>

</style>
