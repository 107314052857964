var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"account id","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"accountID"}},[_vm._v("Merchant/Account ID")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.ID),expression:"form.ID"}],staticClass:"form-control",attrs:{"id":"accountID","readonly":"","type":"text"},domProps:{"value":(_vm.form.ID)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "ID", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"account api key","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"accountApiKey"}},[_vm._v("Account API Key")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.APIKey),expression:"user.APIKey"}],staticClass:"form-control",attrs:{"id":"accountApiKey","readonly":"","type":"text"},domProps:{"value":(_vm.user.APIKey)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "APIKey", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"security code","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"securityCode"}},[_vm._v("Security Code")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.SecurityCode),expression:"user.SecurityCode"}],staticClass:"form-control",attrs:{"id":"securityCode","readonly":"","type":"text"},domProps:{"value":(_vm.user.SecurityCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "SecurityCode", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"organization name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"shopTitle"}},[_vm._v("Organization")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.ShopTitle),expression:"form.ShopTitle"}],staticClass:"form-control",attrs:{"id":"shopTitle","type":"text"},domProps:{"value":(_vm.form.ShopTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "ShopTitle", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"nick name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"ownerNickName"}},[_vm._v("Nick Name")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.OwnerNickName),expression:"user.OwnerNickName"}],staticClass:"form-control",attrs:{"id":"ownerNickName","readonly":"","type":"text"},domProps:{"value":(_vm.user.OwnerNickName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "OwnerNickName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"password"}},[_vm._v("Password")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Password),expression:"form.Password"}],staticClass:"form-control",attrs:{"id":"password","type":"password"},domProps:{"value":(_vm.form.Password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Password", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"email","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"email"}},[_vm._v("E-mail Address")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Email),expression:"form.Email"}],staticClass:"form-control",attrs:{"id":"email","type":"text"},domProps:{"value":(_vm.form.Email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Email", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"google 2FA","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"google2FA"}},[_vm._v("Google Auth(2FA)")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Google2FA),expression:"form.Google2FA"}],staticClass:"form-control",attrs:{"id":"google2FA","readonly":"","type":"text"},domProps:{"value":(_vm.form.Google2FA)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Google2FA", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-offset-4 col-md-8 text-center"},[_c('p',{staticClass:"mb-0 pointer",on:{"click":function($event){return _vm.getTotp('GenerateSecurityID')}}},[_vm._v("Reset 2FA Code")]),_c('img',{attrs:{"src":_vm.qrCode,"alt":""}}),(!_vm.totp)?_c('p',{staticClass:"mb-0 pointer",on:{"click":function($event){return _vm.getTotp('GenerateTOTP')}}},[_c('span',[_vm._v("Click to test 2FA Code"),_c('br'),_vm._v("Only set 2FA if numbers match")])]):_vm._e(),(_vm.totp)?_c('div',{staticClass:"pointer"},[_c('span',[_vm._v("Test 2FA, result: "+_vm._s(_vm.totp))]),_c('div',{staticClass:"progress",staticStyle:{"height":"2px","width":"200px","margin":"8px auto"}},[_c('div',{staticClass:"progress-bar",style:('width:' + _vm.progress + '%'),attrs:{"aria-valuenow":_vm.progress,"aria-valuemax":"100","aria-valuemin":"0","role":"progressbar"}})])]):_vm._e()])])]),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"google 2FA","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"google2FAEnable"}},[_vm._v("Google 2FA On/Off")]),_c('div',{staticClass:"col-md-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Google2FAEnabled),expression:"form.Google2FAEnabled"}],staticClass:"form-control",attrs:{"id":"google2FAEnable"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "Google2FAEnabled", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"false"}},[_vm._v("Off")]),_c('option',{attrs:{"value":"true"}},[_vm._v("On")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"locale","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4",attrs:{"for":"locale"}},[_vm._v("Locale")]),_c('div',{staticClass:"col-md-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Locale),expression:"form.Locale"}],staticClass:"form-control",attrs:{"id":"locale"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "Locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.locales),function(locale,index){return _c('option',{key:index,domProps:{"value":locale.key}},[_vm._v(_vm._s(locale.value)+" ")])}),0),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-flat",attrs:{"type":"submit","disabled":_vm.isLoading}},[_vm._v(" Update "),(_vm.isLoading)?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin ml-8"})]):_vm._e()])])],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }