<template>
  <div id="bsModalCryptoDeposit" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div v-if="transaction === null">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider v-slot="{ errors }" class="form-group" name="input currency" rules="required"
                                    tag="div">
                  <label for="crypto-deposit-inputCurrency">Input Currency</label>
                  <select id="crypto-deposit-inputCurrency" v-model="form.inputCurrency" class="form-control">
                    <option v-for="(inputCurrency, index) in inputCurrencies" :key="index"
                            :value="inputCurrency.ShortName">
                      {{ inputCurrency.Name + ' - ' + inputCurrency.LongName }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" :rules="{
                    required: true,
                    regex:/^\d*\.?\d{1,8}$/
                  }" class="form-group" name="amount" tag="div">
                  <label for="crypto-deposit-amount">Amount</label>
                  <input id="crypto-deposit-amount" v-model="form.Amount" class="form-control"
                         type="text" @keyup="trimValue('Amount')"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="reference" rules="required"
                                    tag="div">
                  <label for="reference">Your Reference</label>
                  <input id="reference" v-model="form.CustomerReferenceNr" class="form-control" type="text">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="text-right">
                  <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                    <span v-if="!isLoading">Crypto deposit</span>
                    <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div v-if="transaction">
            <p class="text-danger text-center text-bold">Caution, do not pay to this address outside this payment
              window!</p>
            <div class="row">
              <div class="col-md-4 font-medium">Transaction ID</div>
              <div class="col-md-8 font-medium">
                <a href="javascript:void(0)" @click="redirectToDetail(transaction)">
                  {{ transaction.TransactionID }}
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 font-medium">Amount</div>
              <div class="col-md-8 font-medium">
                {{ transaction.Amount }} {{ transaction.CoinName }}&emsp;
                <img :src="getIconUrl(transaction.CoinName)" alt="" class="w-20"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 font-medium">Status</div>
              <div class="col-md-8 font-medium">{{ transaction.Status }}</div>
            </div>
            <div class="row">
              <div class="col-md-4 font-medium">Customer Reference</div>
              <div class="col-md-8 font-medium">{{ transaction.CustomerReferenceNr }}</div>
            </div>

            <div class="no-padding mt-16">
              <va-tabs :padding="'padding: 10px 0 0'">
                <div id="crypto" slot="content" data-title="Crypto">
                  <div class="text-center">
                    <p class="fs-24 text-primary mb-0">{{ timer }}</p>
                    <a v-if="isPaid" :href="transaction.RedirectTargetURL">Click to redirect</a>
                    <div v-if="!isPaid && !isExpired">
                      <br/>
                      <img :src="'https://chart.googleapis.com/chart?chs=300&cht=qr&chl=' + transaction.coinAddress"
                           alt=""/>
                      <br/>
                      <input ref="copy" :value="transaction.coinAddress" type="hidden"/>
                      {{ transaction.coinAddress }}&emsp;
                      <button class="btn btn-outline-success btn-sm d-inline-block"
                              @click="copyToClipboard(transaction.coinAddress)">
                        <i class="fa fa-clipboard"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div id="instant" slot="content" class="active text-center" data-title="Instant">
                  <p class="fs-24 text-primary mb-0">{{ timer }}</p>
                  <a v-if="isPaid" :href="transaction.RedirectTargetURL">Click to redirect</a>
                  <div v-if="!isPaid && !isExpired">
                    <br/>
                    <img :src="'https://chart.googleapis.com/chart?chs=300&cht=qr&chl=' + cryptoLink"
                         alt=""/>
                  </div>
                </div>
                <div id="fiat" slot="content" class="text-center" data-title="Fiat/Card">
                  <p class="fs-24 text-primary mb-0">{{ timer }}</p>
                  <a v-if="isPaid" :href="transaction.RedirectTargetURL">Click to redirect</a>
                  <div v-if="!isPaid && !isExpired">
                    <div v-html="transaction.PaymentDetail"></div>
                  </div>
                </div>
              </va-tabs>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveNotification } from "@/lib/utils";
import VaTabs from "../../VATabs";

export default {
  name: "CryptoDepositModal",
  components: {
    VaTabs,
  },
  data() {
    return {
      isLoading: false,
      title: "",
      inputCurrencies: [],
      data: null,
      transaction: null,
      form: {
        Checkout: true,
        MerchantID: "",
        SecurityCode: "",
        AltCoinID: "",
        inputCurrency: "",
        Amount: "",
        CustomerReferenceNr: "",
        output: "json",
      },
      confirmHook: () => {
      },
      interval: null,
      timer: null,
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "onlineAltCoins",
    ]),
    isPaid() {
      return (
        this.transaction &&
        (this.transaction.Status.toLowerCase() === "paid" ||
          this.transaction.Status.toLowerCase() === "underpaid")
      );
    },
    isExpired() {
      return (
        this.transaction && this.transaction.Status.toLowerCase() === "expired"
      );
    },
    cryptoLink() {
      return (
        this.transaction.CoinName.toLowerCase() +
        ":" + this.transaction.coinAddress + "?amount=" + this.transaction.Amount
      );
    },
  },
  methods: {
    trimValue(key) {
      this.form[key] = this.form[key] ? this.form[key].trim() : "";
    },
    confirm() {
      this.confirmHook();
      this.close();
    },
    onSubmit() {
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/MerchantAPI`, {
        params: payload,
      }).then(response => {
        this.isLoading = false;
        this.reset();
        this.$toast.fire("", "Transaction created successfully", "success");
        saveNotification("Transaction created successfully");
        setTimeout(() => {
          this.transaction = response.data;
          if (this.transaction) {
            this.title = "Invoice";
            this.$emit("updateTransactions");
            this.startTimer(this.transaction.ExpiryTime);
          }
        }, 50);
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    getIconUrl(name) {
      const iconName = name.toLowerCase();
      return `https://s3-eu-west-1.amazonaws.com/cointopay/img/${iconName}_dash2.png`;
    },
    reset() {
      this.form = {
        Checkout: true,
        MerchantID: "",
        SecurityCode: "",
        AltCoinID: "",
        inputCurrency: "",
        Amount: "",
        CustomerReferenceNr: "",
        output: "json",
      };
      this.$nextTick(() => this.$refs.observer.reset());
    },
    redirectToDetail(transaction) {
      this.close();
      const id = "T" + transaction.TransactionID.toString().padStart(9, "0");
      this.$router.push({ name: "transaction-detail", params: { id: id } });
    },
    close() {
      $("#bsModalCryptoDeposit").modal("hide");
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(function () {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, function (err) {
        console.error("Async: Could not copy text: ", err);
      });
    },
    startTimer(time) {
      const date = new Date();
      date.setMinutes(date.getMinutes() + time);
      this.interval = setInterval(() => {
        const now = new Date().getTime();
        const diff = date.getTime() - now;

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        this.timer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        if (diff < 0) {
          clearInterval(this.interval);
          this.timer = "Expired";
        }
      }, 1000);
    },
  },
  mounted() {
    this.inputCurrencies = this.onlineAltCoins;
    this.$bus.$on("crypto-deposit-modal", (args) => {
      this.title = args.title;
      this.data = args.data;
      // Populate form
      this.form = {
        ...this.form,
        MerchantID: this.data.MerchantID,
        SecurityCode: this.data.SecurityCode,
        AltCoinID: this.data.ID,
      };
      this.confirmHook = args.confirm;
      $("#bsModalCryptoDeposit").modal("show");
    });
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>

<style lang="scss" scoped>
.fs-24 {
  font-size: 24px;
}

.nav-tabs-custom {
  box-shadow: none !important;
}
</style>
