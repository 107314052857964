<template>
  <div aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       id="bsModalAssignRole" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" aria-label="Close" class="close" type="button"><span
            aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="bsModalLabel">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <input v-model="form.MerchantID" type="hidden"/>
              <div class="form-group">
                <label>Merchant</label>
                <div>{{ user && user.ShopTitle }}</div>
              </div>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="role" tag="div">
                <label for="role">Role</label>
                <v-select id="role" v-model="form.RoleID" :options="roles" :reduce="role => role.ID"
                          taggable multiple label="Name"></v-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Assign Role</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveNotification } from "@/lib/utils";

export default {
  name: "AssignRoleModal",
  data() {
    return {
      isLoading: false,
      title: "",
      user: null,
      form: {
        MerchantID: "",
        RoleID: "",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "roles",
    ]),
  },
  methods: {
    confirm: function () {
      this.confirmHook();
      this.close();
    },
    onSubmit: function () {
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      if (typeof payload.RoleID === "object") {
        payload.RoleID = payload.RoleID.join(",");
      }
      const url = process.env.VUE_APP_AUTHORIZATION_API_URL;
      this.$http.post(`${url}/?Call=AssignRoles`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          // Close modal
          this.confirm();
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalAssignRole").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("assign-role-modal", (args) => {
      this.title = args.title;
      this.user = args.data || null;
      this.confirmHook = args.confirm;
      $("#bsModalAssignRole").modal("show");
      const roles = this.user.Roles ? this.user.Roles.split(",") : [];
      const userRoles = this.roles.filter(role => {
        return roles.indexOf(role.ID.toString()) !== -1;
      });
      this.form = {
        MerchantID: this.user.ID,
        RoleID: userRoles,
      };
    });
  },
};
</script>

<style scoped></style>
