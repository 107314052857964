<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="form-group">
              <div class="row">
                <label class="col-md-4" for="logo">POS Logo</label>
                <div class="col-md-8">
                  <input id="logo" type="file" @change="uploadFile()" ref="avatar"/>
                  <div v-if="avatar" class="mt-8">
                    <silent-box :image="img"></silent-box>
                  </div>
                </div>
              </div>
            </div>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="social url" tag="div">
              <div class="row">
                <label class="col-md-4" for="socialUrl">Social URL</label>
                <div class="col-md-8">
                  <input id="socialUrl" v-model="form.SocialURL" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="facebook" tag="div">
              <div class="row">
                <label class="col-md-4" for="socialFacebook">Facebook</label>
                <div class="col-md-8">
                  <input id="socialFacebook" v-model="form.SocialFacebook" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="twitter" tag="div">
              <div class="row">
                <label class="col-md-4" for="socialTwitter">Twitter</label>
                <div class="col-md-8">
                  <input id="socialTwitter" v-model="form.SocialTwitter" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="linkedIn" tag="div">
              <div class="row">
                <label class="col-md-4" for="socialLinkedIn">Linked In</label>
                <div class="col-md-8">
                  <input id="socialLinkedIn" v-model="form.SocialLinkedIn" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="form-group" name="reddit" tag="div">
              <div class="row">
                <label class="col-md-4" for="socialReddit">Reddit</label>
                <div class="col-md-8">
                  <input id="socialReddit" v-model="form.SocialReddit" class="form-control" type="text"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <div class="text-right">
              <button type="submit" class="btn btn-primary btn-flat" :disabled="isLoading">
                Update
                <span v-if="isLoading">
                  <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                </span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { SAVE_ACCOUNT } from "@/store/keys";
import { generatePayload, logout, saveNotification } from "@/lib/utils";

export default {
  name: "Pos",
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        SocialURL: "",
        SocialTwitter: "",
        SocialFacebook: "",
        SocialReddit: "",
        SocialLinkedIn: "",
      },
      avatar: "",
      img: {},
      isLoading: false,
    };
  },
  watch: {
    user(val) {
      if (val) {
        this.updateValues();
      }
    },
  },
  methods: {
    updateValues() {
      if (this.user) {
        this.form = {
          SocialURL: this.user.SocialURL,
          SocialTwitter: this.user.SocialTwitter,
          SocialFacebook: this.user.SocialFacebook,
          SocialReddit: this.user.SocialReddit,
          SocialLinkedIn: this.user.SocialLinkedIn,
        };
        this.avatar = this.user.Avatar;
        if (this.avatar) {
          this.img = {
            src: this.avatar,
            thumbnailHeight: "60px",
          };
        }
      }
    },
    uploadFile() {
      const files = this.$refs.avatar.files;
      if (files.length > 0) {
        const file = files[0];
        if (file.type.startsWith("image/")) {
          const fd = new FormData();
          fd.append("file123", file);
          fd.append("MerchantID", this.user.ID);
          // Upload it to amazon S3
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.post(`${apiBaseUrl}/MarketAPI?POSBLOB`, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            let oldFile = "";
            if (response.status === 200) {
              this.$refs.avatar.value = "";
              this.avatar = response.data;
              if (Object.keys(this.user).length > 0) {
                const payload = { ...this.user };
                if (payload.Avatar) {
                  const url = payload.Avatar.split("ID=");
                  oldFile = url[url.length - 1];
                }
                payload.Avatar = this.avatar;
                this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
                  if (response.status === 200) {
                    // Delete old file if any
                    if (oldFile) {
                      this.$http.get(`${apiBaseUrl}/MarketAPI?POSBLOB`, {
                        params: {
                          MerchantID: this.user.ID,
                          deleteString: oldFile,
                        },
                      }).catch(error => {
                        console.log(error);
                      });
                    }
                    this.$emit("refresh", this.user.ID);
                    // Show success toast
                    this.$toast.fire("", "Merchant logo updated successfully", "success");
                    saveNotification("Merchant logo updated successfully");
                  }
                }).catch(error => {
                  this.$toast.fire("", error.data.message, "error");
                });
              }
            }
          }).catch(error => {
            console.log(error);
          });
        } else {
          // Clear file
          this.$refs.avatar.value = "";
          this.$toast.fire("", "Please provide a valid image file", "error");
        }
      }
    },
    onSubmit: function () {
      const form = JSON.parse(JSON.stringify(this.form));
      let payload = { ...this.user };
      payload.SocialURL = form.SocialURL;
      payload.SocialTwitter = form.SocialTwitter;
      payload.SocialFacebook = form.SocialFacebook;
      payload.SocialReddit = form.SocialReddit;
      payload.SocialLinkedIn = form.SocialLinkedIn;
      payload = generatePayload(payload);
      this.isLoading = true;
      this.$store.dispatch(SAVE_ACCOUNT, payload).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.$emit("refresh", this.user.ID);
          this.$toast.fire("", "Social information updated successfully", "success");
          saveNotification("Social information updated successfully");
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data.message, "error");
        if (error.data.status_code === 400 && error.data.message.toLowerCase() === "no valid session, login again.") {
          logout();
        }
      });
    },
  },
  mounted() {
    this.updateValues();
  },
};
</script>

<style scoped></style>
